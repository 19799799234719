import { FC, useCallback, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Grid, Paper, TextField } from '@mui/material';
import { PlayArrow } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { routes } from 'config';
import { UserForgotPassword } from 'clients/users/userClient.types';
import { userClient } from 'clients/users/userClient';
import { validEmail } from 'shared/helpers/validation';
import { LayoutContext } from 'contexts/LayoutContext';
import { ReCaptchaComponent } from 'shared/components/reCaptcha/ReCaptchaComponent';


interface Props {
    onSubmitButtonText: string;
}

const DEFAULT_VALUES: UserForgotPassword = {
    email: ''
};

export const ResetPasswordForm: FC<Props> = ({ onSubmitButtonText }) => {

    const { isMobile }= useContext(LayoutContext);
    const navigate = useNavigate();

    const { handleSubmit, control, formState: { isSubmitting, errors }} = useForm<UserForgotPassword>({
        defaultValues: {
            ...DEFAULT_VALUES,
        },
    });

    const onSubmit = useCallback(async (data: UserForgotPassword) => {
        try {
            await userClient.forgotPassword(data);
            navigate(routes.checkEmail, {
                state: {
                    title: 'Twoje hasło zostało pomyślnie zresetowane'
                },
            })
        } catch (err) {

        }
    }, [navigate]);



    return (
        <Paper sx={{ minWidth: 400, padding: isMobile ? 4 : 8, display: 'flex', justifyContent: 'center', borderRadius: 8 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ReCaptchaComponent />
                    <Grid item xs={12}>
                        <Controller
                            name="email"
                            control={control}
                            rules={{
                                required: 'To pole jest wymagane',
                                pattern: {
                                    value: validEmail,
                                    message: 'Nieprawidłowy format email',
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    variant="outlined"
                                    label='Email'
                                    name="email"
                                    error={!!errors.email}
                                    helperText={errors.email?.message}
                                    sx = {{ minWidth: 300}}
                                />
                            )}
                        />
                    </Grid>
                <Grid item xs={12} mt={2} display="flex" justifyContent={isMobile ? "center" : "end"}>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        sx={theme => ({ backgroundColor: theme.palette.primary.main })}
                        endIcon={<PlayArrow />}
                    >
                        {onSubmitButtonText}
                    </LoadingButton>
                </Grid>
            </form>
        </Paper>
    );
};
