import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { cacheKeys } from 'config';
import { attachmentClient } from 'clients/attachments/attachmentClient';


export const useAttachmentsTypes = () => {
    const { data: { data: attachmentTypes } = {}, status, error } = useQuery(
        [cacheKeys.getAttachmentTypes],
        attachmentClient.getAttachmentTypes,
    );

    return {
        status,
        error: error as AxiosError,
        fileTypes: attachmentTypes || [],
    };
};
