import React, { useCallback, useContext, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { useDropzone } from 'react-dropzone';
import { Box, Grid, InputLabel, MenuItem, styled, Select, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';
import { CheckCircleOutlined, Close, PlayArrow } from '@mui/icons-material';
import { cacheKeys } from 'config';
import { useAttachmentsTypes } from 'shared/hooks/attachment/useAttachmentTypes';
import { LayoutContext } from 'contexts/LayoutContext';
import { CreateAttachment } from 'clients/attachments/attachmentClient.types';
import { attachmentClient } from 'clients/attachments/attachmentClient';

const StyledDropzone = styled(Grid)<{
    error?: boolean;
}>(({ error=false }) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 16,
    width: '100%',
    border: error ? '2px dashed red' : '1px dashed #C6C6C6',
    borderRadius: 29,
}));

const StyledBox = styled(Box)(() => ({
    position: 'absolute',
    top: 10,
    right: 20,
    cursor: 'pointer',
    color: 'grey',
}))

type FormCrud = CreateAttachment;

interface Props {
    defaultValues?: Partial<FormCrud>;
    edit?: boolean;
    onSubmitRequest?: (values: FormCrud ) => void,

}

const DEFAULT_VALUES = {
    file: undefined,
    fileName: '',
    fileType: '',
    fileDate: null,
}

export const AttachmentsForm: React.FC<Props> = ({
    defaultValues=  {},
    edit=false,
    onSubmitRequest
}) => {

    const { fileTypes } = useAttachmentsTypes();
    const { isMobile }= useContext(LayoutContext);
    const queryClient = useQueryClient();

    const {handleSubmit, watch, reset, control, setValue, formState: { isSubmitting, errors }} = useForm<FormCrud>({
        defaultValues: {
            ...DEFAULT_VALUES,
            ...defaultValues
        }
    });
    const file = watch('file')

    useEffect(() => {
        if (file) {
            setValue('fileName', file.path || '')
        } else {
            if (!edit) {
                setValue('fileName', '')
            }
        }
    },[file, setValue, edit])

    const {
        getRootProps,
        getInputProps,
    } = useDropzone({
        onDrop: files => {
            setValue("file", files[0]);
        },
        multiple: false,
        noClick: !!file,
        noDrag: !!file,
    });

    const onSubmit = useCallback(async (data: FormCrud) => {
        try {
            if (onSubmitRequest) {
                await onSubmitRequest(data)
            } else {
                await attachmentClient.createAttachment(data);
            }
            await queryClient.invalidateQueries([cacheKeys.getAttachments])
            reset();

        } catch (err) {
            console.log(err)
        }
    }, [queryClient, reset, onSubmitRequest]);

    const removeFile = () => {
        // @ts-ignore
        setValue('file', undefined)
    }


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                {!edit && <Grid item xs={12}>
                    <Controller
                        name='file'
                        control={control}
                        rules={{
                            required: 'To pole jest wymagane',
                        }}
                        render={({field}) => (
                            <StyledDropzone {...field} {...getRootProps()} error={!!errors.file}>
                                <input type='file' {...getInputProps()} />
                                {!file &&
                                    <Grid item xs={12} display='flex' flexDirection='column' alignItems='center'>
                                        <img src='/assets/add-file.svg' alt='Add File'/>
                                        <Typography variant='body2' mt={2} textAlign='center'>
                                            Kliknij w powyższą ikonę aby dodać dokumenty, albo przeciągnij pliki w te
                                            pole.
                                        </Typography>
                                    </Grid>
                                }
                                {file &&
                                    <Grid item xs={12} display='flex' flexDirection='column' alignItems='center'>
                                        <CheckCircleOutlined fontSize='large' color='secondary'/>
                                        <Typography variant='body2' mt={2} textAlign='center'>
                                            Plik został dodany
                                        </Typography>
                                        <StyledBox>
                                            <Close onClick={removeFile}/>
                                        </StyledBox>
                                    </Grid>
                                }
                            </StyledDropzone>
                        )}
                    />
                </Grid>}
            <Grid item xs={12}>
                <Controller
                    name="fileName"
                    control={control}
                    rules={{
                        required: 'To pole jest wymagane',
                        validate: name => name?.length <= 100 || 'Maksymalna liczba znakow to 100',
                    }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label='Nazwa pliku'
                            error={!!errors.fileName}
                            helperText={errors.fileName?.message}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <InputLabel id='fileType'>Typ Pliku</InputLabel>
                <Controller
                    name='fileType'
                    control={control}
                    rules={{
                        required: 'To pole jest wymagane',
                    }}
                    render={({ field }) => (
                        <Select
                            {...field}
                            labelId='fileType'
                            sx={{
                                '.MuiSelect-select': {
                                    padding: 1,
                                    fontSize: '13.5px',
                                    position: 'relative',
                                    left: '12px',
                                },
                            }}
                            fullWidth
                            error={!!errors.fileType}
                        >
                            {fileTypes.map(({id, fileType}) => (
                                <MenuItem key={id} value={id}>{fileType}</MenuItem>
                            ))}
                        </Select>
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Controller
                    name='fileDate'
                    control={control}
                    render={({ field }) => (
                        <DatePicker
                            {...field}
                            inputFormat="dd.MM.yyyy"
                            label="Data Pliku"
                            mask="__.__.____"
                            showDaysOutsideCurrentMonth
                            maxDate={new Date()}
                            openTo="year"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                />
                            )}
                        />
                    )}
                />
            </Grid>

                <Grid item xs={12} display="flex" justifyContent={isMobile ? "center" : "end"}>
                    <LoadingButton
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        sx={theme => ({ backgroundColor: theme.palette.primary.main })}
                        endIcon={<PlayArrow />}
                    >
                        {edit ? 'EDYTUJ DOKUMENT' : 'DODAJ DOKUMENT'}
                    </LoadingButton>
                </Grid>
            </Grid>
        </form>
    );
}

