import {AxiosError, AxiosResponse} from 'axios';
import {request} from 'clients/client';
import {PaginatedResults} from 'shared/helpers/Query';
import {keysToCamel, keysToSnake} from 'shared/helpers/object';
import {formatDate} from 'shared/helpers/date';
import {
    CodeEmailParams,
    RefreshToken, UserChangeData, UserChangePassword,
    UserForgotPassword,
    UserLogin,
    UserRegister,
} from './userClient.types';
import recaptchaVerify, {reCaptchaHeaders} from './reCaptcha';
import {env} from "../../env";


const usersApiBaseUrl = env.REACT_APP_API_URL;


const login = async (data: UserLogin) => {
    const token = await recaptchaVerify("LOGIN")

    return request({
        authenticate: false,
        options: {
            url: `${usersApiBaseUrl}token/`,
            method: 'POST',
            data,
            headers: reCaptchaHeaders(token, "LOGIN")
        }
    });
}

const refreshToken = async (data: RefreshToken) => {

    return request({
        authenticate: false,
        options: {
            url: `${usersApiBaseUrl}token/refresh/`,
            method: 'POST',
            data,
        }
    });
}

const signup = async (data: UserRegister) => {
    const token = await recaptchaVerify("REGISTER")

    return request({
        authenticate: false,
        options: {
            url: `${usersApiBaseUrl}users/register/`,
            method: 'POST',
            data: {...keysToSnake(data), birth_date: formatDate(data?.birthDate || new Date())},
            headers: reCaptchaHeaders(token, "REGISTER")
        }
    });
}

const forgotPassword = async (data: UserForgotPassword) => {
    const token = await recaptchaVerify("PASSWORD_RESET")

    return request({
        authenticate: false,
        options: {
            url: `${usersApiBaseUrl}users/init_password_reset/`,
            method: 'POST',
            data,
            headers: reCaptchaHeaders(token, "PASSWORD_RESET")
        }
    });
}
const getMe = (): Promise<AxiosResponse<PaginatedResults<UserRegister>, AxiosError>> => {
    return request({
        options: {
            url: `${usersApiBaseUrl}users/`,
            method: 'GET',
        },
    }).then((data: any): AxiosResponse<PaginatedResults<UserRegister>> => ({
            ...data.data,
            data: data.data.results.map(keysToCamel)[0]
        })
    );
};

const verifyEmail = async (params: CodeEmailParams) => {

    return request({
        options: {
            url: `${usersApiBaseUrl}users/verify_email/`,
            method: 'GET',
            params
        },
    })
};

const confirmPasswordReset = async (params: CodeEmailParams) => {

    return request({
        options: {
            url: `${usersApiBaseUrl}users/confirm_password_reset/`,
            method: 'GET',
            params
        },
    })
};

const changePassword = async (data: UserChangePassword) => {

    return request({
        options: {
            url: `${usersApiBaseUrl}users/password_change/`,
            method: 'POST',
            data: {...keysToSnake(data)},
        },
    })
};

const changePersonalData = async (data: UserChangeData, id: number) => {

    return request({
        options: {
            url: `${usersApiBaseUrl}users/${id}/`,
            method: 'PATCH',
            data: {...keysToSnake(data), birth_date: formatDate(data?.birthDate || new Date())},
        },
    })
};


export const userClient = {
    changePassword,
    changePersonalData,
    login,
    getMe,
    signup,
    refreshToken,
    forgotPassword,
    verifyEmail,
    confirmPasswordReset
};