import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { cacheKeys } from 'config';
import { userClient } from 'clients/users/userClient';

type Options = {
    enabled: boolean
};

const defaultOptions: Partial<Options> = {
    enabled: true,
};

export type Params = {
    id: string | number;
};

export const useMe = (options: Partial<Options> = defaultOptions) => {
    const { data: { data: user } = {}, status, error } = useQuery(
        [cacheKeys.getUser],
        userClient.getMe,
        { enabled: options.enabled },
    );
    
    return {
        status,
        error: error as AxiosError,
        user: user || {},
    };
};
