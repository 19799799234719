import React, { useCallback, useEffect, useMemo, createContext, FC, ReactNode, useState, useReducer } from 'react';
import { useQueryClient } from "@tanstack/react-query";
import jwt_decode from 'jwt-decode'

import { userClient } from 'clients/users/userClient';
import {RefreshToken, UserLogin} from 'clients/users/userClient.types';
import { cacheKeys } from 'config';
import { ContextProps} from "./UserContext.types";
import { useMe}  from 'shared/hooks/user/useMe';
import {clearToken, getAccessToken, getRefreshToken, setToken} from "../../shared/helpers/token";

export const defaultContext: ContextProps = {
    isLoggedIn: false,
    login: () => {},
    logout: () => {},
    user: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        sex: '',
        city: '',
        email: '',
        genderIdentity: '',
        forceChangePassword: false,
        birthDate: '',
    },
};

export const UserContext = createContext(defaultContext);
export const UserContextProvider: FC<{ children?: ReactNode }> = ({ children }) => {

    const [logged, setLogged] = useState<boolean>();
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const queryClient = useQueryClient();

    const { user: userMe } = useMe( { enabled: !!logged });
    const isLoggedIn = useMemo(() => Object.keys(userMe).length > 0 || logged, [userMe, logged]);

    const user = useMemo(() => {
        const user = Object.assign(userMe)
        if (user.firstName){
            localStorage.setItem('firstName', user.firstName)
        }
        return user
    }, [userMe]);

    const login = async (data: UserLogin) => {
        try {
            const response = await userClient.login(data);
            if (!!response.data){
                setToken(response.data)
                setLogged(true)
            }
            return Promise.resolve({
                success: !!response,
            });
        } catch (error: any) {
            return Promise.reject({
                success: false,
                error: error.code,
                message: error.message,
            });
        }
    };

    const getNewTokens = useCallback(async (data: RefreshToken) => {
        try {
            const response = await userClient.refreshToken(data);
            return response
        } catch (err) {
            console.log(err)
        }
    }, [])

    const logout = useCallback(async () => {
        await queryClient.invalidateQueries([cacheKeys.getUser])
        queryClient.clear();
        clearToken();
        setLogged(false)
        forceUpdate()
    }, [queryClient]);

    useEffect(() => {
        const accessToken = getAccessToken();
        const refreshToken = getRefreshToken();

        if (!!accessToken && !!refreshToken) {
            let decodedToken = jwt_decode(accessToken);
            let currentDate = new Date();
            // @ts-ignore
            if (decodedToken.exp * 1000 > currentDate.getTime()) {
                setLogged(true);
            } else {
                getNewTokens({ "refresh": refreshToken}).then(
                    data => {
                        if (!!data?.data){
                            localStorage.setItem("accessToken", data?.data.access);
                        }
                        setLogged(true);
                    }).catch(() => setLogged(false))
            }
        } else {
            setLogged(false);
        }
    }, [getNewTokens])



    return (
        <UserContext.Provider
            value={{
                isLoggedIn,
                login,
                logout,
                user,
            }} >
            {children}
        </UserContext.Provider>
    );
}
