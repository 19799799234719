import React, {Fragment, useContext, useRef} from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Container, CircularProgress } from '@mui/material';
import { routes } from 'config';
import { Dashboard } from 'views/Public/Dashboard/Dashboard';
import { NavBar } from 'shared/components/layout/Nav';
import { Footer } from 'shared/components/layout/Footer';
import { Register } from 'views/LoggedOut/Register/Register';
import { LogIn } from 'views/LoggedOut/LogIn/LogIn';
import { Authenticated, NotAuthenticated } from 'shared/components/auth/NotAuthenticated';
import { Account } from 'views/LoggedIn/Account/Account';
import { Sidebar } from 'shared/components/layout/Sidebar';
import { Attachments } from 'views/LoggedIn/Attachments/Attachments';
import { History } from 'views/LoggedIn/History/History';
import { UserContext } from 'contexts/UserContext/UserContext';
import { AddData } from './LoggedIn/AddData/AddData';
import { ResetPassword } from './LoggedOut/ResetPassword/ResetPassword';
import { EmailVerification } from './LoggedOut/EmailVerification/EmailVerification';
import { CheckEmail } from 'shared/components/checkEmail/CheckEmail';
import { ConfirmPasswordReset } from './LoggedOut/ConfirmPasswordReset/ConfirmPasswordReset';
import { PDFViewer } from './LoggedIn/PDFViewer/PDFViewer';
import { ChangePassword } from './LoggedIn/ChangePassword/ChangePassword';

export const Root = () => {

    const { isLoggedIn, user } = useContext(UserContext);
    const about = useRef<HTMLDivElement>(null);
    const faq = useRef<HTMLDivElement>(null);

    if (isLoggedIn === undefined) {
        return ( <Fragment>Loading...</Fragment>
        )
    }

    if (user.forceChangePassword) {
        return (
            <Container>
                <Authenticated>
                    <ChangePassword />
                </Authenticated>
            </Container>
        )
    }


    if (Object.keys(user).length === 0 && isLoggedIn){
        return (
            <CircularProgress color="secondary" />
        )
    }

    return (
        <Fragment>
            <NavBar aboutRef={about} faqRef={faq} />
            <Container>
                <NotAuthenticated>
                    <Routes>
                        <Route path={routes.dashboard} element={<Dashboard aboutRef={about} faqRef={faq}/>}/>
                        <Route path={routes.register} element={<Register/>}/>
                        <Route path={routes.login} element={<LogIn/>}/>
                        <Route path={routes.resetPassword} element={<ResetPassword/>}/>
                        <Route path={routes.emailVerification} element={<EmailVerification/>}/>
                        <Route path={routes.checkEmail} element={<CheckEmail/>}/>
                        <Route path={routes.confirmPasswordReset} element={<ConfirmPasswordReset/>}/>
                        <Route path="*" element={<Navigate to={routes.dashboard}/>} />
                    </Routes>
                </NotAuthenticated>
                <Authenticated>
                    <Sidebar/>
                    <Routes>
                        <Route path={routes.attachments} element={<Attachments/>}/>
                        <Route path={routes.account} element={<Account/>}/>
                        <Route path={routes.history} element={<History/>}/>
                        <Route path={routes.addData} element={<AddData/>}/>
                        <Route path={routes.changePassword} element={<ChangePassword/>}/>
                        <Route path={routes.viewPDF()} element={<PDFViewer/>}/>
                        <Route path={routes.confirmPasswordReset} element={<ConfirmPasswordReset/>}/>
                        <Route path="*" element={<Navigate to={routes.account}/>} />
                    </Routes>
                </Authenticated>
            </Container>
            <Footer />
        </Fragment>
    )
}