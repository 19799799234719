import React, { useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import { UserContext } from 'contexts/UserContext/UserContext';
import { themePalette } from 'shared/styles/muiTheme';
import { ChangePassword } from 'views/LoggedIn/ChangePassword/ChangePassword';
import { ChangePersonalData } from './partials/ChangePersonalDataForm';

export const Account = () => {

    const { user } = useContext(UserContext);

    return (
        <Grid container justifyContent="center" gap={1}>
            <Grid item xs={12} display="flex" justifyContent="center">
                <Typography component="span" variant='h1' color={themePalette.palette.primary.main}>
                    Witaj,
                </Typography>
                <Typography component="span" variant='h1' ml={1} color={themePalette.palette.secondary.main}>
                    {user.firstName || localStorage.getItem('firstName')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <ChangePersonalData />
            </Grid>
            <Grid item xs={12}>
                <ChangePassword />
            </Grid>
        </Grid>
    )
};