import React, { useCallback, useContext, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Alert, Grid, Link, Typography } from '@mui/material';
import { routes } from 'config';
import { LayoutContext } from 'contexts/LayoutContext';
import { UserRegister } from 'clients/users/userClient.types';
import { userClient } from 'clients/users/userClient';
import { themePalette } from 'shared/styles/muiTheme';
import { RegisterUserAuth } from './partials/RegisterAuthForm';


export const Register = () => {

    const { isMobile } = useContext(LayoutContext);
    const navigate = useNavigate();
    const [error, setError] = useState(false)

    const onSubmit = useCallback( async(data: UserRegister) => {
        try {
            data.agreements = data.agreements?.filter(aggr => aggr)
            await userClient.signup(data)
            navigate(routes.checkEmail, {
                state: {
                    title: 'Dziękujemy za założenie konta'
                },
            })
        } catch(err) {
            setError(true);
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }, [navigate])

    return (
        <Grid container flexDirection="column" sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <Grid item>
                <Typography component={isMobile ? "h1" : "span"} variant='h1' color={ themePalette.palette.secondary.main }>
                    Rejestracja /
                </Typography>
                <Typography component="span" variant='body2'>
                    Masz już konto?
                </Typography>
                <Link sx={{ textDecoration: 'none', marginLeft: 1 }} component={RouterLink} to={routes.login}>
                    Zaloguj się
                </Link>
            </Grid>
            <Grid item mt={2}>
                {error &&
                    <Alert variant="outlined" severity="error">
                        Nie udało się stworzyć konta
                    </Alert>
                }
                <RegisterUserAuth
                    onSubmitRequest={onSubmit}
                    onSubmitButtonText='REJESTRUJ'
                />
            </Grid>

        </Grid>
    )
}