import React, { useState, useContext } from 'react';
import { Divider, Grid, Paper, Typography } from '@mui/material';
import { LayoutContext } from 'contexts/LayoutContext';
import { AttachmentsForm } from './partials/AttachmentsForm';
import { themePalette } from 'shared/styles/muiTheme';
import { AttachmentsView} from "./partials/AttachmentsList";
import { AttachmentsFilter} from "./partials/AttachmentsFilter";


export const Attachments = () => {

    const [showFilters, setShowFilters] = useState(true)
    const { isMobile } = useContext(LayoutContext);

    return (
        <Grid container flexDirection="column">
            <Grid item xs={12} display="flex" alignItems="start">
                <Typography textAlign="start" component={isMobile ? "h1" : "span"} variant='h1' color={themePalette.palette.primary.main}>
                    Zarządzaj plikami
                </Typography>
            </Grid>

            <Paper sx={{ padding: isMobile ? 4 : 6, borderRadius: 8 }}>
                <AttachmentsForm />
                <Grid item my={2}>
                    <Divider />
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant='h3'>Twoje dokumenty</Typography>
                    <Typography variant='body2' sx={{ cursor: 'pointer' }} onClick={() => setShowFilters(!showFilters)}>
                        {showFilters ? 'Schowaj filtry' : 'Pokaż filtry'}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <AttachmentsFilter show={showFilters}>
                        {({ filters }) => (
                            <AttachmentsView filters={filters}/>
                        )}
                    </AttachmentsFilter>
                </Grid>


            </Paper>
        </Grid>
    )
}