import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { themePalette } from 'shared/styles/muiTheme';
import {useLocation} from "react-router-dom";

export const CheckEmail = () => {

    const { state } = useLocation();

    return (
        <Grid container flexDirection="column" sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <Paper>
                <Grid container padding={5} display="flex" flexDirection="column">
                    <img  src='/assets/checkEmail.svg' alt='check Email'/>
                    <Typography component="h1" variant='h1' color={themePalette.palette.secondary.main}>
                        {state?.title}
                    </Typography>
                    <Typography variant='body2' fontSize={20} textAlign="center">
                        Sprawdź swoją pocztę email, aby ukończyć krok
                    </Typography>
                </Grid>
            </Paper>
        </Grid>
    );
}