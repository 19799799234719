import React, { Fragment, useCallback, useState } from "react";
import {
    Box,
    Dialog,
    DialogTitle,
    TableBody,
    TableCell,
    TableHead,
    Table,
    TableRow,
    TableContainer,
    Grid,
    DialogContent,
    IconButton,
    Skeleton, Typography
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { useDiseases } from 'shared/hooks/diseases/useDiseases';
import { diseasesClient } from 'clients/diseases/diseasesClient';
import { DiseasesType, DiseaseType } from 'clients/diseases/diseasesClient.types';
import { DiseaseForm } from './form/DiseaseForm';
import {cacheKeys} from 'config';
import {useQueryClient} from "@tanstack/react-query";

export const Diseases = () => {

    const [editedId, setEditedId] = useState('')
    const [open, setOpen] = useState(false);
    const queryClient = useQueryClient();

    const handleClose = () => {
        setOpen(false);
    };
    const { diseases, isLoading } = useDiseases();

    const onSubmit = useCallback(async (data: DiseasesType) => {
        try {
            await diseasesClient.editDisease(data, editedId);
            handleClose()
        } catch (err) {
            console.log(err);
        }
    }, [editedId]);
    const editRow = (disease: DiseaseType) => {
        setEditedId(disease.id);
        setOpen(true);
    }

    const onDelete = useCallback(async (id: string) => {
        try {
            await diseasesClient.deleteDisease(id);
            await queryClient.invalidateQueries([cacheKeys.getAllDiseases]);
        } catch (err) {
            console.log(err)
        }
    }, [queryClient]);

    if (isLoading) {
        return (
            <Box sx={{ width: '100%' }}>
                {[...Array(5)].map((e, i) => <Skeleton height='50px' animation='pulse' />)}
            </Box>
        )
    }
    if (!isLoading && diseases && diseases.length === 0) {
        return (
            <Grid container justifyContent='center'>
                <Typography variant='body2'>
                    Nie masz dodanych chorób
                </Typography>
            </Grid>
        )
    }

    return (
        <Fragment>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell><strong>Nazwa</strong></TableCell>
                        <TableCell><strong>Data Diagnozy</strong></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {diseases && diseases.length > 0 && diseases.map((dis: DiseaseType) => (
                        <TableRow key={dis.id}>
                            <TableCell> {dis.name} </TableCell>
                            <TableCell> {dis.diagnose_date} </TableCell>
                            <TableCell width={90}>
                                <IconButton sx={{ padding: 1 }} onClick={() => editRow(dis)}>
                                    <Edit cursor='pointer'/>
                                </IconButton>
                                <IconButton sx={{ padding: 1 }} onClick={() => onDelete(dis.id)}>
                                    <Delete cursor='pointer'/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle sx={theme => ({ fontSize: 28, color: theme.palette.secondary.main })}>
                Edytuj chorobę
            </DialogTitle>
            <DialogContent>
                <DiseaseForm
                    defaultValues={{
                        name: diseases.find(dis => dis.id === editedId)?.name,
                        diagnoseDate: new Date(diseases.find(dis => dis.id === editedId)?.diagnose_date || '')
                    }}
                    onSubmitRequest={onSubmit}
                    edit={true}
                />
            </DialogContent>
        </Dialog>
        </Fragment>

    )
}