import {AxiosResponse} from "axios";
import {request} from 'clients/client';
import {keysToSnake} from 'shared/helpers/object';
import {formatDate} from 'shared/helpers/date';
import {PaginatedResults} from 'shared/helpers/Query';
import {DiseasesSearchType, DiseasesType, DiseaseType, FoundDiseaseType} from './diseasesClient.types';
import {env} from "../../env";

const diseaseApiBaseUrl = env.REACT_APP_API_URL;

const createDisease = async (data: DiseasesType) => {

    return request({
        options: {
            url: `${diseaseApiBaseUrl}diseases/`,
            method: 'POST',
            data: {...keysToSnake(data), diagnose_date: formatDate(data?.diagnoseDate || new Date())},
        }
    });
}

const editDisease = async (data: DiseasesType, id: string) => {

    return request({
        options: {
            url: `${diseaseApiBaseUrl}diseases/${id}`,
            method: 'PATCH',
            data: {...keysToSnake(data), diagnose_date: formatDate(data?.diagnoseDate || new Date())},
        }
    });
}

const deleteDisease = async (id: string): Promise<AxiosResponse>=> {

    return request({
        options: {
            url: `${diseaseApiBaseUrl}diseases/${id}`,
            method: 'DELETE',
        }
    });
}
const getDiseases = async (data: DiseasesSearchType) => {

    return request({
        options: {
            url: `${diseaseApiBaseUrl}synonyms/diseases/`,
            method: 'GET',
            params: {
                search: data.search,
                limit: data.limit ?? 10,
            },
        }
    }).then((data): PaginatedResults<FoundDiseaseType> => data.data)
}

const getAllDiseases = async () => {

    return request({
        options: {
            url: `${diseaseApiBaseUrl}diseases/`,
            method: 'GET',
        }
    }).then((data): PaginatedResults<DiseaseType> => data.data)
}

export const diseasesClient = {
    createDisease,
    deleteDisease,
    getDiseases,
    getAllDiseases,
    editDisease,
}