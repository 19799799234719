export const routes = {
    dashboard: '/dashboard',
    register: '/register',
    login: '/login',
    resetPassword: '/reset-password',
    changePassword: '/change-password',
    account: '/account',
    attachments: '/attachments',
    healthInfo: '/health-info',
    history: '/history',
    addData: '/add-data',
    emailVerification: '/weryfikacja-email',
    checkEmail: '/check-email',
    confirmPasswordReset: '/potwierdz-reset-hasla',
    viewPDF: (fileId: string = ':fileId') => `/view-pdf/${fileId}`,
}

export const cacheKeys = {
    getUser: 'getUser',
    getAgreements: 'getAgreements',
    getDiseases: 'getDiseases',
    getAllDiseases: 'getAllDiseases',
    getAllSymptoms: 'getAllSymptoms',
    getSymptoms: 'getSymptoms',
    getMedicalHistory: 'getMedicalHistory',
    getVerifyEmail: 'getVerifyEmail',
    getConfirmPasswordReset: 'getConfirmPasswordReset',
    getAttachmentTypes: 'getAttachmentTypes',
    getAttachments: 'getAttachments',
    getAttachment: 'getAttachment',
    createDiagnosis: 'createDiagnosis',
    createSymptom: 'createSymptom',

}

export const drawerWidth = '350px'
export const IMAGE_EXTENSIONS = [".jpg", ".jpeg", ".webp", ".png"]
export const PDF_EXTENSIONS = [".pdf"]
