import { useQuery } from '@tanstack/react-query';
import { cacheKeys } from 'config';
import { SymptomsSearchType } from 'clients/symptoms/symptomsClient.types';
import { symptomsClient } from 'clients/symptoms/symptomsClient';


export const useSymptomsSearch = (params: SymptomsSearchType) => {
    const { data: { results: symptoms } = {}, status, isLoading } = useQuery(
        [cacheKeys.getSymptoms, params],
        () => symptomsClient.getSymptoms(params),
    );


    return {
        status,
        isLoading,
        symptoms: symptoms || [],
    };
};
