import React, {FC, Fragment, ReactElement, useState} from 'react';
import { Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useAttachmentsTypes } from 'shared/hooks/attachment/useAttachmentTypes';

export type FiltersType = {
    fileType?: string;
    fileDateAfter: Date | null;
    fileDateBefore: Date | null;
}

const DEFAULT_FILTERS: FiltersType = {
    fileType: 'all',
    fileDateAfter: null,
    fileDateBefore: null,
};

type Props = {
    defaultFilters?: FiltersType,
    children(props: {
        filters: FiltersType,
    }): ReactElement;
    show: boolean,
}

export const AttachmentsFilter: FC<Props> = ({ defaultFilters = DEFAULT_FILTERS, children, show=false }) => {

    const initialFilters = {
        ...DEFAULT_FILTERS,
        ...defaultFilters,
    };

    const { fileTypes } = useAttachmentsTypes();
    const [fileType, setFileType] = useState(initialFilters?.fileType);
    const [fileDateAfter, setFileDateAfter] = useState(initialFilters?.fileDateAfter);
    const [fileDateBefore, setFileDateBefore] = useState(initialFilters?.fileDateBefore);

    if (!show) {
        return (
            <Fragment>
                {children({
                    filters: {
                        fileType: fileType === 'all' ? undefined : fileType,
                        fileDateAfter: fileDateAfter,
                        fileDateBefore: fileDateBefore,
                    },
                })}
            </Fragment>
        )
    }
    return (
        <Grid container spacing={2} mt={1} justifyContent='space-between'>
            <Grid item xs={12} sm={4}>
                <Typography variant="body2">Typ Dokumentu: </Typography>
                <Select
                    labelId='fileType'
                    sx={{
                        '.MuiSelect-select': {
                            padding: 1,
                            fontSize: '13.5px',
                            position: 'relative',
                            left: '12px',
                        },
                    }}
                    name='selectFileTye'
                    defaultValue='all'
                    fullWidth
                    onChange={(event, object) =>  setFileType(event.target.value as string)}
                >
                    <MenuItem value='all'>All</MenuItem>
                    {fileTypes.map(({id, fileType}) => (
                        <MenuItem key={id} value={id}>{fileType}</MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Typography variant="body2">Minimalna Data Dokumentu</Typography>
                <DatePicker
                    inputFormat="dd.MM.yyyy"
                    value={fileDateAfter}
                    onChange={(value, keyboardInputValue) => setFileDateAfter(value)}
                    mask="__.__.____"
                    showDaysOutsideCurrentMonth
                    maxDate={new Date()}
                    openTo="year"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Typography variant="body2">Maksymalna Data Urodzenia Pacjenta</Typography>
                <DatePicker
                    inputFormat="dd.MM.yyyy"
                    value={fileDateBefore}
                    onChange={(value, keyboardInputValue) => setFileDateBefore(value)}
                    mask="__.__.____"
                    showDaysOutsideCurrentMonth
                    maxDate={new Date()}
                    openTo="year"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                        />
                    )}
                />
            </Grid>

            <Grid item xs={12}>
                {children({
                    filters: {
                        fileType: fileType === 'all' ? undefined : fileType,
                        fileDateAfter: fileDateAfter,
                        fileDateBefore: fileDateBefore,
                    },
                })}
            </Grid>
        </Grid>
    );
};