import { useQuery } from '@tanstack/react-query';
import { cacheKeys } from 'config';
import { DiseasesSearchType } from 'clients/diseases/diseasesClient.types';
import { diseasesClient } from 'clients/diseases/diseasesClient';


export const useDiseasesSearch = (params: DiseasesSearchType) => {
    const { data: { results: diseases} = {}, status, isLoading } = useQuery(
        [cacheKeys.getDiseases, params],
        () => diseasesClient.getDiseases(params),
    );


    return {
        status,
        isLoading,
        diseases: diseases || [],
    };
};
