
const  toCamel = (s: string): string =>  {
    return s.replace(/([-_][a-z])/gi, ($1) => {
        return $1.toUpperCase().replace('-', '').replace('_', '');
    });
}

const toSnake = (str: string) => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);


export const keysToCamel = (o: any): unknown => {
    if (o === Object(o) && !Array.isArray(o) && typeof o !== 'function') {
        const n: Record<string, any> = {};
        // @ts-ignore
        Object.keys(o).forEach((k) => {
            n[toCamel(k)] = keysToCamel(o[k]);
        });
        return n;
    } else if (Array.isArray(o)) {
        return o.map((i) => {
            return keysToCamel(i);
        });
    }
    return o;
}

export const keysToSnake = (o: any): Record<string, any> => {
    if (o === Object(o) && !Array.isArray(o) && typeof o !== 'function') {
        const n: Record<string, any> = {};
        // @ts-ignore
        Object.keys(o).forEach((k) => {
            n[toSnake(k)] = keysToSnake(o[k]);
        });
        return n;
    }
    else if (Array.isArray(o)) {
        return o.map((i) => {
            return keysToCamel(i);
        });
    }
    return o;
}

