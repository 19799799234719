import React, { Fragment, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import {
    Person,
    Menu as MenuIcon,
    History,
    Login,
    PersonAdd,
    Plagiarism,
    ExitToApp,
    Add
} from '@mui/icons-material';
import { UserContext } from 'contexts/UserContext/UserContext';
import { routes } from 'config';
import { themePalette } from 'shared/styles/muiTheme';

export const MobileMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { isLoggedIn, logout } = useContext(UserContext);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const mainMenuItems = [
        { id: 'login', title: 'ZALOGUJ SIĘ', link: routes.login, icon: <Login/>, logged: false},
        { id: 'register', title: 'REJESTRACJA', link: routes.register, icon: <PersonAdd/>, logged: false},
        { id: 'account', title: 'ZARZĄDZAJ SWOIMI DANYMI', link: routes.account, icon: <Person/>, logged: true },
        { id: 'add-data', title: 'DODAJ DANE', link: routes.addData, icon: <Add/>, logged: true },
        { id: 'history', title: 'HISTORIA CHOROBY', link: routes.history, icon: <History/>, logged: true},
        { id: 'attachments', title: 'ZARZĄDZANIE PLIKAMI', link: routes.attachments, icon: <Plagiarism/>, logged: true},
    ].filter(({logged}) => logged === isLoggedIn)

    return (
        <Fragment>
            <IconButton
                sx={theme => ({ color: theme.palette.secondary.main })}
                onClick={handleClick}
            >
                <MenuIcon style={{ fontSize: 36 }}/>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        color: themePalette.palette.primary.light,
                        '& .MuiMenuItem-root': {
                            marginY: 1,
                        },
                        '& .MuiSvgIcon-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                            color: themePalette.palette.primary.light,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {mainMenuItems.map(({ id, title, link, icon }: any) => (
                    <MenuItem component={RouterLink} to={link} key={id}>
                        <ListItemIcon> {icon}</ListItemIcon>
                        <ListItemText> {title} </ListItemText>
                    </MenuItem>
                    ))
                }
                {isLoggedIn &&
                    <MenuItem onClick={() => logout()}>
                        <ListItemIcon> <ExitToApp/> </ListItemIcon>
                        <ListItemText> WYLOGUJ </ListItemText>
                    </MenuItem>
                }

            </Menu>
        </Fragment>
    );
}