import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { UserContext } from 'contexts/UserContext/UserContext';


export const Authenticated: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

    const { isLoggedIn } = useContext(UserContext);

    if (isLoggedIn) {
        return (
            <Box mt={12} minHeight='65vh'
                 sx={{
                     display: 'flex',
                     alignItems: 'start',
            }}>
                {children}
            </Box>
        );
    }
    return null;
};export const NotAuthenticated: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

    const { isLoggedIn } = useContext(UserContext);

    if (!isLoggedIn) {
        return (
            <Box mt={12} minHeight='65vh' sx={{ display: 'flex', alignItems: 'center'}}>
                {children}
            </Box>
        );
    }
    return null;
};