import {request} from 'clients/client';
import {Agreements, GetAgreementsParams} from "./agreementClient.types";
import {AxiosResponse} from 'axios';
import {keysToCamel} from 'shared/helpers/object';
import {env} from "../../env";

const baseApiUrl = env.REACT_APP_API_URL;

const getAgreements = (params: GetAgreementsParams) => {
    return request({
        authenticate: false,
        options: {
            url: `${baseApiUrl}agreements/`,
            method: 'GET',
            params: {
                display_location: params.displayLocation,
            },
        },
    }).then((data: any): AxiosResponse<Agreements[]> => ({
            ...data.data,
            data: data.data.results.map(keysToCamel)
        })
    );
};

export const agreementsClient = {
    getAgreements,
};