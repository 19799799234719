import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { cacheKeys } from 'config';
import { attachmentClient } from 'clients/attachments/attachmentClient';
import {GetAttachmentFilterParams} from 'clients/attachments/attachmentClient.types';


export const useAttachments = (params: GetAttachmentFilterParams) => {
    const { data: { data: attachments } = {}, status, error } = useQuery(
        [cacheKeys.getAttachments, params],
        () => attachmentClient.getAttachments(params),
    );

    return {
        status,
        error: error as AxiosError,
        attachments: attachments || [],
    };
};
