import React, {FC, useState, ReactNode, createContext } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { LayoutContextProps } from './LayoutContext.types'


const defaultContext: LayoutContextProps = {
    isMobile: false,
    menuOpen: true,
    setMenuOpen: () => null,
};


export const LayoutContext = createContext(defaultContext);

export const LayoutContextProvider: FC<{ children?: ReactNode }> = ({ children }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [menuOpen, setMenuOpen] = useState(false);


    return (
        <LayoutContext.Provider
            value={{
                isMobile,
                menuOpen,
                setMenuOpen
            }}
        >
            {children}
        </LayoutContext.Provider>
    );
};
