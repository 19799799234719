import React from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import { Alert, Box, Button, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import { themePalette } from 'shared/styles/muiTheme';
import { useVerifyEmail } from 'shared/hooks/verifyEmail/useVerifyEmail';
import {routes} from "../../../config";
import {PlayArrow} from "@mui/icons-material";


export const EmailVerification = () => {

    const [ searchParams ] = useSearchParams();
    const code = searchParams.get("code");
    const navigate = useNavigate();
    const { status } = useVerifyEmail({code: code || ''}, { enabled: !!code})


    return (
        <Grid container flexDirection="column" sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <Grid item xs={12}>
                <Paper>
                    <Box p={5}>
                        <Typography component="h1" variant='h1' color={themePalette.palette.primary.main}>
                            Weryfikacja Adresu Email
                        </Typography>
                        {status==='loading' &&
                            <Grid item xs={12} display="flex" justifyContent="center" mt={3}>
                                <CircularProgress />
                            </Grid>
                        }
                        {status==='error' &&
                            <Grid item xs={12} mt={2}>
                                <Alert variant="outlined" severity="error">
                                    Link aktywacyjny nie jest poprawny
                                </Alert>
                            </Grid>

                        }
                        {status === 'success' &&
                            <Grid item display='flex' flexDirection='column' alignItems='center' mt={2}>
                                <Typography component="h2" variant='body2' fontSize={20} textAlign="center">
                                    Konto zostało pomyślnie założone
                                </Typography>
                                <Button
                                    variant="contained"
                                    sx={theme => ({
                                        backgroundColor: theme.palette.primary.main,
                                        alignSelf: 'end',
                                        marginTop: 1,
                                    })}
                                    onClick={() => navigate(routes.login)}
                                    endIcon={<PlayArrow />}
                                >
                                    PRZEJDZ DO LOGOWANIA
                                </Button>
                            </Grid>
                        }
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    )
}