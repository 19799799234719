import React, { Fragment, useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Chip,
    Divider,
    Grid,
    styled,
    Typography,
    IconButton,
    CircularProgress,
    Dialog,
    DialogTitle, DialogContent
} from '@mui/material';
import {Download, FindInPageOutlined, Delete, Edit} from '@mui/icons-material';
import {cacheKeys, IMAGE_EXTENSIONS, PDF_EXTENSIONS, routes} from 'config';
import {Attachment, CreateAttachment} from 'clients/attachments/attachmentClient.types';
import { LayoutContext } from 'contexts/LayoutContext';
import {FiltersType} from "./AttachmentsFilter";
import {useAttachments} from "shared/hooks/attachment/useAttachments";
import {attachmentClient} from "clients/attachments/attachmentClient";
import {useQueryClient} from "@tanstack/react-query";
import {AttachmentsForm} from "./AttachmentsForm";

const StyledGrid = styled(Grid)
(() => ({
    border: '1px solid #EFEFEF',
    borderRadius: '40px',
    padding: '8px 20px',
    marginTop: 8,
}));

const StyledGridItem = styled(Grid)(() => ({
    paddingLeft: 10,
    '& #title': {
        color: '#BABABA',
        fontSize: 12,
    }
}));

const StyledChip = styled(Chip)<{
    background?: string;
}>(({ background, theme }) => ({
    backgroundColor: background ?? 'inherit',
    fontSize: 12,
    color: '#959595',
}));


export const AttachmentsList: React.FC<Attachment> = ({ fileExtension, fileName, file, fileType, fileDate, id}) => {

    const { isMobile }= useContext(LayoutContext);
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const shouldView = useMemo(() => {
        return IMAGE_EXTENSIONS.includes(fileExtension) || PDF_EXTENSIONS.includes(fileExtension)
    }, [fileExtension]);

    const onEdit = useCallback(async (data: CreateAttachment) => {
        try {
            await attachmentClient.editAttachment(data, id);
            handleClose()
        } catch (err) {
            console.log(err);
        }
    }, [id]);

    const onDelete = useCallback(async (id: string) => {
        try {
            await attachmentClient.deleteAttachment(id);
            await queryClient.invalidateQueries([cacheKeys.getAttachments]);
        } catch (err) {
            console.log(err)
        }
    }, [queryClient]);

    const EditionMode = () => {
        return (
            <Box display='flex'>
                <IconButton onClick={() => onDelete(id)} sx={{ padding: 0.1 }}>
                    <Delete />
                </IconButton>
                <IconButton onClick={() => setOpen(true)} sx={{ padding: 0.1 }}>
                    <Edit cursor='pointer'/>
                </IconButton>
            </Box>
        )
    }

    return (
        <StyledGrid container>
            <StyledGridItem item xs={8} sm={4}>
                <Typography id='title'>Nazwa pliku</Typography>
                <Typography variant='body2' fontSize={14}>{fileName}</Typography>
            </StyledGridItem>
            {!isMobile && <Divider orientation="vertical" flexItem/>}
            {isMobile && <Grid item xs={12} my={1}>
                <Divider flexItem/>
            </Grid>}
            <StyledGridItem item xs={6} sm={3}>
                <Typography id='title'>Typ pliku</Typography>
                <StyledChip label={fileType?.fileType} size='small' background={fileType?.color}/>
            </StyledGridItem>
            <Divider orientation="vertical" flexItem />
            <StyledGridItem item xs={5} sm={2}>
                <Typography id='title'>Data dokumentu</Typography>
                <Typography variant='body2' fontSize={14}>{fileDate}</Typography>
            </StyledGridItem>
            {!isMobile && <Divider orientation="vertical" flexItem/>}
            {isMobile && <Grid item xs={12} my={1}>
                <Divider flexItem/>
            </Grid>}
                {shouldView &&
                    <StyledGridItem item xs={12} md={2} display='flex' justifyContent='space-between' alignItems='center'>
                        <IconButton onClick={() => navigate(routes.viewPDF(id))} sx={{ minWidth: 110 }}>
                            <FindInPageOutlined color='primary' fontSize='medium'/>
                            <Typography color='primary'>Podgląd</Typography>
                        </IconButton>
                        <Divider orientation="vertical" flexItem/>
                        <EditionMode/>
                    </StyledGridItem>
                }
                {!shouldView &&
                    <StyledGridItem item xs={12} md={2} display='flex' justifyContent='space-between' alignItems='center'>
                        <IconButton color='primary' component="a" download href={file} sx={{ minWidth: 110 }}>
                            <Download/>
                            <Typography color='primary'>Pobierz</Typography>
                        </IconButton>
                        <Divider orientation="vertical" flexItem/>
                        <EditionMode/>
                    </StyledGridItem>
                }

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle sx={theme => ({ fontSize: 28, color: theme.palette.secondary.main })}>
                    Edytuj dokument
                </DialogTitle>
                <DialogContent>
                    <AttachmentsForm
                        defaultValues={{
                            fileName: fileName,
                            fileType: fileType?.id,
                            fileDate: new Date(fileDate)
                        }}
                        onSubmitRequest={onEdit}
                        edit={true}
                    />
                </DialogContent>
            </Dialog>
        </StyledGrid>
    )
}

type Filters = {
    filters: FiltersType
}
export const AttachmentsView: React.FC<Filters> = ({ filters }) => {
    const { attachments, status } = useAttachments({
        ...filters
    });


    if (status === 'loading') {
        return (
            <CircularProgress />
        )
    }

    return (
        <Fragment>
            {attachments.map((attachment) => <AttachmentsList key={attachment.id} {...attachment}/>)}
        </Fragment>
    )


}