import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    AccordionSummaryProps,
    Grid,
    styled,
    Typography,
} from '@mui/material';
import {ArrowDropDownCircleOutlined} from '@mui/icons-material';


const StyledAccordion = styled(Accordion)((theme) => ({
    boxShadow: 'none',
    '&:before': {
        display: 'none',
    },
    '& .MuiCollapse-wrapper': {
        borderBottom: `1px solid ${theme.theme.palette.primary.main}`,

    }
}));


const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
    <AccordionSummary
        expandIcon={<ArrowDropDownCircleOutlined/>}
        {...props}
    />
))(({theme}) => ({
    flexDirection: 'row-reverse',

    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
        fontWeight: 600,
        width: '100%',
        color: theme.palette.primary.main,
        marginLeft: theme.spacing(1),
        fontSize: 15,
        padding: 10,
        borderBottom: `1px solid #E6E6E6`,
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
        borderBottom: 'none',
    },
    '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
    },
    '& Mui-Paper.Mui-expanded': {
        borderBottom: '1px solid blue',
    },
}));
export const FAQ = () => {

    return (
        <Grid container padding={4}>
            <Grid item>
                <Typography variant='h2' sx={theme => ({color: theme.palette.primary.main})}>FAQ</Typography>
            </Grid>
            <StyledAccordion>
                <StyledAccordionSummary> 1. Dlaczego akurat moja historia może komuś pomóc? </StyledAccordionSummary>
                <AccordionDetails>
                    <Typography variant='body2'>
                        Każda droga do diagnostyki choroby rzadkiej może być inna. Jedni pacjenci otrzymują rozpoznanie
                        po kilku miesiącach, a inni odbywają tzw. odyseję diagnostyczną, która potrafi potrwać nawet
                        kilka lat. Kolejne objawy potrafią manifestować się w różnym okresie czasu i mogą wskazywać na
                        mylne ścieżki diagnostyczne. Często wymaga to wcześniejszego wykluczenia innych, bardziej lub
                        mniej powszechnych chorób. Tylko ogląd całościowy na znaczną grupę pacjentów pomaga wychwycić
                        elementy wspólne i charakterystyczne dla danego schorzenia. Jeśli w danej populacji występuję
                        jedynie kilkunastu pacjentów z określoną jednostką chorobową, każda ich historia może być na
                        wagę złota dla świata nauki i medycyny.
                    </Typography>
                </AccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary> 2. Czy muszę chorować na chorobę rzadką, aby przekazać swoje
                    dane? </StyledAccordionSummary>
                <AccordionDetails>
                    <Typography variant='body2'>
                        Konto Pacjenta Fundacji Saventic przeznaczone jest dla zdiagnozowanych już osób cierpiących na
                        choroby rzadkie jak i dla prawnych opiekunów pacjentów ze zdiagnozowanymi rzadkimi schorzeniami.
                        Tylko takie przypadki pozostają w sferze naszych zainteresowań.
                    </Typography>
                </AccordionDetails>
            </StyledAccordion>
            <Grid item xs={12}>
                <StyledAccordion>
                    <StyledAccordionSummary> 3. Czy moja choroba jest rzadka? </StyledAccordionSummary>
                    <AccordionDetails>
                        <Typography variant='body2'>
                            Aby sprawdzić, czy zdiagnozowana u Ciebie choroba zaliczana jest do rzadkich, zajrzyj na stronę:
                            www
                        </Typography>
                    </AccordionDetails>
                </StyledAccordion>
            </Grid>
            <Grid item xs={12}>
                <StyledAccordion>
                    <StyledAccordionSummary> 4. Czy mogę przekazać dane anonimowe? </StyledAccordionSummary>
                    <AccordionDetails>
                        <Typography variant='body2'>
                            Tak, jeśli tylko podstawowe dane medyczne pozostaną czytelne, istnieje możliwość, aby przekazać
                            nam dane w pełni zanonimizowane.
                        </Typography>
                    </AccordionDetails>
                </StyledAccordion>
            </Grid>
            <StyledAccordion>
                <StyledAccordionSummary> 5. Jakiego rodzaju dane będą szczególnie pomocne?</StyledAccordionSummary>
                <AccordionDetails>
                    <Typography variant='body2'>
                        Każdy wynik badań laboratoryjnych oraz specjalistycznych badań diagnostycznych, testy
                        wykluczające inne choroby, wypisy ze szpitali oraz opisy prywatnych konsultacji lekarskich. Tak
                        na dobrą sprawę, każdy dokument medyczny, zwłaszcza sprzed rozpoznania choroby, jest dla nas
                        ogromnie istotny.
                    </Typography>
                </AccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary> 6. Czy moje dane będą bezpieczne? </StyledAccordionSummary>
                <AccordionDetails>
                    <Typography variant='body2'>
                        Dokładamy wszelkich starań, by Twoje dane były bezpieczne. W tym celu wykorzystujemy nowoczesne technologie,
                        w szczególności Google IAM, Google Secret Manager, JWT, SSL, na bieżąco weryfikujemy, co dzieje się z Twoimi danymi,
                        zatrudniamy specjalistów w zakresie cyberbezpieczeństwa oraz przeprowadzamy audyty w zakresie ochrony danych osobowych.                    </Typography>
                </AccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary>7. Komu będą przekazywane moje dane? </StyledAccordionSummary>
                <AccordionDetails>
                    <Typography variant='body2'>
                        Zanonimizowane dane będą przekazane spółce Saventic Health, która zajmuje się tworzeniem oraz rozwijaniem
                        algorytmów mających na celu pomoc w diagnozwaniu chorób rzadkich oraz ultrarzadkich. Spółka prowadzi działalność komercyjną.
                        Przekazanie danych do spółki Saventic Health sp. z o.o. może nastąpić tylko wówczas, gdy dane będą anonimowe.
                        Anonimizacja polega na przekształceniu danych osobowych w sposób uniemożliwiający ich przyporządkowanie do zidentyfikowanej lub możliwej do zidentyfikowania osoby fizycznej.                    </Typography>
                </AccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary>
                    8. Czy mogę zrezygnować z udostępniania moich danych?
                </StyledAccordionSummary>
                <AccordionDetails>
                    <Typography variant='body2'>
                        Oczywiście, decyzja w tym zakresie należy wyłącznie do Ciebie. W każdej chwili możesz usunąć wszystkie dane udostępnione
                        w ramach Portalu Pacjenta Zdiagnozowanego i/lub cofnąć zgodę na ich przetwarzanie, a w razie jakichkolwiek problemów lub
                        wątpliwości związanych z tymi czynnościami, skontaktować się z nami.
                        Chętnie Ci pomożemy. Musimy Cię jednak poinformować, że cofnięcie zgody na przetwarzanie danych nie wpływa na prawidłowość
                        przetwarzania przed jej wycofaniem, czyli np. na przeprowadzoną przed wycofaniem zgody anonimizację.                    </Typography>
                </AccordionDetails>
            </StyledAccordion>
        </Grid>
    )
}