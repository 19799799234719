import React, { useContext } from 'react';
import { Container, Grid, Paper, styled, Typography } from '@mui/material';
import { common } from '@mui/material/colors';
import { LayoutContext } from 'contexts/LayoutContext';

const Main = styled(Paper)((theme) => ({
    variant: 'footer',
    backgroundColor: theme.theme.palette.primary.light,
    backgroundImage: `url(/assets/mapka-droga1.svg)`,
    backgroundSize: 'contain',
    backgroundRepeat: 'repeat-x',
    width: '100%',
    marginTop: 32,
    padding: 32,
}));
export const Footer = () => {

    const { isMobile }= useContext(LayoutContext)

    return (
        <Main>
            <Container>
                <Grid container display="flex"  alignItems="start" minHeight='200px'>
                    <Grid item xs={12} sm={6} display="flex" justifyContent="center">
                        <img src='/assets/logo_white.svg' alt='logo'/>
                    </Grid>
                    <Grid item xs={12} sm={3} mt={1} color={common.white} textAlign={isMobile ? 'center' : 'start'}>
                            <Typography variant='h3'> Fundacja Saventic </Typography>
                            <Typography variant='h4'> Polna 66 lok. 12, </Typography>
                            <Typography variant='h4'> 87-100 Toruń </Typography>

                            <Typography variant='h4' mt={4}> KRS 0000900701 </Typography>
                            <Typography variant='h4'> NIP 8792731768 </Typography>
                            <Typography variant='h4'> REGON 389031157 </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3} mt={1} color={common.white} textAlign={isMobile ? 'center' : 'start'}>
                        <Typography variant='h3'> Kontakt </Typography>
                        <Typography variant='h4'> info@fundacjasaventic.pl </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Main>
    )
}