import axiosRequest, { AxiosResponse } from 'axios';
import { getAccessToken } from 'shared/helpers/token'

export type AbstractRequest = (props: { options: any; authenticate?: boolean; maxRetries?: number; }) => Promise<AxiosResponse>;

export const request: AbstractRequest =  async ({ options, authenticate = true, maxRetries = 3 }): Promise<AxiosResponse> => {
    const token = getAccessToken();
    return axiosRequest({
        ...options,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...(authenticate && {
                'Authorization': 'Bearer ' + token,
            }),
            ...options.headers,
        }
    }).catch((e) => {
        throw(e);
    });
};