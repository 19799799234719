import React, { useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import { themePalette } from 'shared/styles/muiTheme';
import { LayoutContext } from 'contexts/LayoutContext';
import { ResetPasswordForm } from './partials/ResetPasswordForm';


export const ResetPassword = () => {

    const { isMobile } = useContext(LayoutContext);

    return (
        <Grid container flexDirection="column" sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <Grid item>
                <Typography component={isMobile ? "h1" : "span"} variant='h1' color={themePalette.palette.primary.main}>
                    Przypomnij hasło
                </Typography>
            </Grid>
            <Grid item mt={2}>
                <ResetPasswordForm onSubmitButtonText='ZRESETUJ HASŁO'/>
            </Grid>
        </Grid>
    )
}