import { request} from 'clients/client';
import { keysToSnake} from 'shared/helpers/object';
import { formatDate} from 'shared/helpers/date';
import { FoundSymptomsType, SymptomsSearchType, SymptomsType, SymptomType} from './symptomsClient.types';
import { PaginatedResults} from 'shared/helpers/Query';
import { env } from "../../env";
import {AxiosResponse} from "axios/index";

const symptomsApiBaseUrl = env.REACT_APP_API_URL;

const createSymptom = async (data: SymptomsType) => {

    return request({
        options: {
            url: `${symptomsApiBaseUrl}symptoms/`,
            method: 'POST',
            data: {...keysToSnake(data), diagnose_date: formatDate(data?.diagnoseDate || new Date())},
        }
    });
}

const editSymptom = async (data: SymptomsType, id: string) => {

    return request({
        options: {
            url: `${symptomsApiBaseUrl}symptoms/${id}`,
            method: 'PUT',
            data: {...keysToSnake(data), diagnose_date: formatDate(data?.diagnoseDate || new Date())},
        }
    });
}

const getSymptoms = async (data: SymptomsSearchType) => {

    return request({
        options: {
            url: `${symptomsApiBaseUrl}synonyms/symptoms/`,
            method: 'GET',
            params: {
                search: data.search,
                limit: data.limit ?? 10,
            },
        }
    }).then((data): PaginatedResults<FoundSymptomsType> => data.data)
}

const getAllSymptoms = async () => {

    return request({
        options: {
            url: `${symptomsApiBaseUrl}symptoms/`,
            method: 'GET',
        }
    }).then((data): PaginatedResults<SymptomType> => data.data)
}

const deleteSymptom = async (id: string): Promise<AxiosResponse>=> {

    return request({
        options: {
            url: `${symptomsApiBaseUrl}symptoms/${id}`,
            method: 'DELETE',
        }
    });
}

export const symptomsClient = {
    createSymptom,
    deleteSymptom,
    editSymptom,
    getAllSymptoms,
    getSymptoms,
}