import React, {RefObject, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import {AppBar, Button, Container, Grid, MenuItem, Toolbar } from '@mui/material';
import { routes } from 'config';
import { themePalette } from 'shared/styles/muiTheme';
import { UserContext } from 'contexts/UserContext/UserContext';
import { LayoutContext } from 'contexts/LayoutContext';
import { MobileMenu } from './MobileMenu';


type Props = {
    aboutRef: RefObject<HTMLDivElement>;
    faqRef: RefObject<HTMLDivElement>;
}

export const NavBar: React.FC<Props> = ({ aboutRef: about, faqRef: faq}) => {

    const navigate = useNavigate();
    const { isLoggedIn } = useContext(UserContext);
    const { isMobile }= useContext(LayoutContext)

    const scrollToSection = (elementRef: any) => {
        if (window.location.pathname !== '/dashboard'){
            navigate(routes.dashboard)
        }

        window.scrollTo({
            top: elementRef.current.offsetTop - 70,
            behavior: 'smooth',
        });
    };

    if (isLoggedIn && !isMobile){
        return null;
    }

    return (
        <AppBar>
            <Container>
                <Toolbar>
                    <Grid container display="flex" justifyContent='space-between' alignItems="center">
                        <Grid item sx={{ cursor: 'pointer' }} onClick={() => navigate(routes.dashboard)}>
                            <img src='/assets/Logo.svg' height="40px" width="100%" alt='logo'/>
                        </Grid>
                        {!isMobile && <Grid item color={themePalette.palette.textColor.dark} display="flex" alignItems="center"
                               gap={3}>
                            <MenuItem onClick={() => {
                                scrollToSection(about)
                            }}>
                                O NAS
                            </MenuItem>
                            <Button variant="outlined" onClick={() => navigate(routes.login)}>
                                ZALOGUJ SIĘ
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => navigate(routes.register)}
                                sx={{
                                    color: themePalette.palette.secondary.main,
                                    borderColor: themePalette.palette.secondary.main,
                                }}
                            >
                                REJESTRACJA
                            </Button>
                        </Grid>
                        }
                        {isMobile && <MobileMenu />}
                    </Grid>

                </Toolbar>
            </Container>
        </AppBar>
    )
}