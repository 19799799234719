import { Box, Grid, Typography } from '@mui/material';
import { common } from '@mui/material/colors';
import { themePalette } from 'shared/styles/muiTheme';
import { PersonAddAlt, LocalLibrary, Groups } from '@mui/icons-material';
export const Steps = () => {

    return (
        <Grid container sx={{
            backgroundColor: themePalette.palette.textColor.dark,
            color: common.white,
            marginTop: 3,
            position: 'relative',
            padding: 8,
        }}>
            <Grid item sx={{
                position: 'absolute',
                top: -20,
                backgroundColor: themePalette.palette.primary.main,
                padding: 2,
            }}>
                <Typography variant="h2" color={common.white}>
                    Jak to działa?
                </Typography>
            </Grid>
            <Grid container justifyContent="space-between">
                <Grid item xs={12} sm={3}>
                    <Typography textAlign="center" variant="h1" color={themePalette.palette.primary.main}>1</Typography>
                    <Box display="flex" alignItems="start" fontSize={30}>
                        <PersonAddAlt style={{ fontSize: 40 }}/>
                        <Typography ml={1} fontSize={25} display='inline'>Zarejestruj się</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Typography textAlign="center" variant="h1" color={themePalette.palette.primary.main}>2</Typography>
                    <Box display="flex" alignItems="start" fontSize={30}>
                        <LocalLibrary style={{ fontSize: 40 }}/>
                        <Typography ml={1} fontSize={25} display='inline'>Prześlij swoją dokumentację w formie zdjeć lub skanów</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography textAlign="center" variant="h1" color={themePalette.palette.primary.main}>3</Typography>
                    <Box display="flex" alignItems="start" fontSize={30}>
                        <Groups style={{ fontSize: 40 }}/>
                        <Typography ml={1} fontSize={25} display='inline'>Nasz zespół zajmie się resztą</Typography>
                    </Box>
                </Grid>
            </Grid>


        </Grid>

    )
}