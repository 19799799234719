import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {Alert, AlertColor, Autocomplete, Grid, styled, TextField} from '@mui/material';
import debounce from 'lodash/debounce';
import { PlayArrow } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';
import { cacheKeys } from 'config';
import { LayoutContext } from 'contexts/LayoutContext';
import { DiseasesType } from 'clients/diseases/diseasesClient.types';
import { diseasesClient } from 'clients/diseases/diseasesClient';
import { useDiseasesSearch } from 'shared/hooks/diseases/useDiseasesSearch';

type FormCrud = DiseasesType;

const DEFAULT_VALUES: Partial<FormCrud> = {
    name: '',
    diagnoseDate: null,
    description: '',
};

const StyledAutocomplete =styled(Autocomplete)(() => ({
    '& .MuiInputBase-root': {
        padding: 0,
        '& .MuiInputBase-input': {
            padding: '8px 18px',
        }
    },
}));


interface Props {
    defaultValues?: Partial<FormCrud>;
    edit?: boolean;
    onSubmitRequest?: (values: FormCrud ) => void,

}

export const DiseaseForm: React.FC<Props> = ({
    defaultValues=  {},
    edit=false,
    onSubmitRequest
}) => {

    const { isMobile } = useContext(LayoutContext);
    const queryClient = useQueryClient();
    const [info, setInfo] = useState(false);
    const [search, setSearch] = useState<string>('');
    const [severityType, setSeverityType] = useState<AlertColor>("error");
    const [alertText, setAlertText] = useState<string>('');

    const { diseases, isLoading } = useDiseasesSearch({search: search, limit: 10});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setQuery = useCallback(debounce(setSearch, 500), [])

    const onSearch = useCallback(
        (value: string) => setQuery(value),
        [setQuery]
    )

    const { handleSubmit, control, reset, formState: { isSubmitting, errors }} = useForm<FormCrud>({
        defaultValues: {
            ...DEFAULT_VALUES,
            ...defaultValues,
        },
    });

    const createDisease = useMutation(diseasesClient.createDisease, {
        mutationKey: [cacheKeys.createDiagnosis],
        onSuccess: (results) => {
            setInfo(true)
            setSeverityType('success');
            setAlertText(`Choroba ${results.data.name} z dnia ${results.data.diagnose_date} została dodana`);
            queryClient.invalidateQueries([cacheKeys.getMedicalHistory]);

        },
        onError: (err) => {
            setInfo(true)
            setSeverityType('error')
            setAlertText(`Niestety nie udało sie dodać rekordu`)
        },
    });

    useEffect(() => {
        setTimeout(() => {
            setInfo(false);
        }, 5000);
    }, [info])


    const onSubmit = useCallback(async (data: FormCrud) => {
        try {
            if (onSubmitRequest) {
                await onSubmitRequest(data)
            } else {
                await createDisease.mutateAsync(data);
                reset();
            }
            await queryClient.invalidateQueries([cacheKeys.getAllDiseases])
            await queryClient.invalidateQueries([cacheKeys.getMedicalHistory])

        } catch (err) {
            console.log(err);
        }
    }, [createDisease, reset, onSubmitRequest, queryClient]);


    return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container mt={1} spacing={2}>
                    <Grid item xs={12} sx={{ overflow: 'hidden', width: '100px'}}>
                        {info &&
                            <Alert variant="outlined" severity={severityType}>
                                {alertText}
                            </Alert>
                        }
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="name"
                            control={control}
                            rules={{
                                required: 'To pole jest wymagane',
                            }}
                            render={({ field }) => (
                                <StyledAutocomplete
                                    {...field}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label='Podaj chorobę'
                                            placeholder='Podaj nazwę choroby'
                                            error={!!errors.name}
                                            helperText ={errors.name?.message}
                                        />
                                    }
                                    options={diseases?.map(disease => disease.label) ?? []}
                                    onInputChange={(e, value) => onSearch(value)}
                                    loading={isLoading}
                                    filterOptions={(x) => x}
                                    clearText='clear'
                                    onChange={(e, value) => {
                                        field.onChange(value);
                                    }}
                                    forcePopupIcon
                                    autoSelect={true}
                                    freeSolo={true}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name='diagnoseDate'
                            rules={{ required: 'To Pole jest wymagane'}}
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    {...field}
                                    inputFormat="dd.MM.yyyy"
                                    label="Data diagnozy"
                                    mask="__.__.____"
                                    showDaysOutsideCurrentMonth
                                    maxDate={new Date()}
                                    openTo="year"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            placeholder='Podaj datę diagnozy'
                                            error={!!errors?.diagnoseDate}
                                            helperText={errors?.diagnoseDate?.message}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                <Grid item xs={12} mt={2} display="flex" justifyContent={isMobile ? "center" : "end"}>
                    <LoadingButton
                        type="submit"
                        sx={theme => ({ backgroundColor: theme.palette.primary.main })}
                        variant="contained"
                        loading={isSubmitting}
                        endIcon={<PlayArrow />}
                    >
                        {edit ? 'EDYTUJ CHOROBĘ' : 'DODAJ CHOROBĘ'}
                    </LoadingButton>
                </Grid>
                </Grid>
            </form>

    );
};

