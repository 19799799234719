import React, { useContext, useState } from 'react';
import {Grid, Paper, Tab, Typography, styled, Accordion, AccordionSummary, AccordionDetails} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { ExpandMore } from '@mui/icons-material';
import { LayoutContext } from 'contexts/LayoutContext';
import { DiseaseForm } from 'views/LoggedIn/Diseases/form/DiseaseForm';
import { SymptopmsForm } from 'views/LoggedIn/Symptoms/form/SymptopmsForm';
import { Diseases } from 'views/LoggedIn/Diseases/Diseases';
import { themePalette } from 'shared/styles/muiTheme';
import {Symptoms} from "../Symptoms/Symptoms";


const StyledTabList = styled(TabList)<{ tab?: string }>(({tab, theme}) => ({
    padding: 0,
    '& .Mui-selected': {
        color: tab==='1' ? '0B8CEE !important' : '#2BCE19 !important',
        fontSize: 18,
        fontFamily: 'Sagnika Bold'
    },
    '& .MuiTabs-indicator': {
        backgroundColor: tab==='1' ? theme.palette.primary.main : theme.palette.secondary.main
    }
}));

const StyledAccordionSummary = styled(AccordionSummary)((theme) => ({
    '& .Mui-selected': {
        color: '#2BCE19 !important',
        fontSize: 18,
        fontFamily: 'Sagnika Bold'
    },
    '& .MuiTabs-indicator': {
        backgroundColor: theme.theme.palette.secondary.main
    }
}));
export const AddData = () => {

    const { isMobile }= useContext(LayoutContext);
    const [value, setValue] = useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    return (
        <Grid container justifyContent="center">
            <Paper sx={{ padding: isMobile ? 4 : 6, borderRadius: 8 }}>
                <Grid container flexDirection="column">
                    <TabContext value={value}>
                    <Grid item xs={12}>
                        <StyledTabList tab={value} onChange={handleChange} centered variant="fullWidth" color='#000'>
                            <Tab label="Choroby" value="1"	/>
                            <Tab label="Symptomy" value="2" />
                        </StyledTabList>
                    </Grid>
                    <TabPanel value="1">
                        <Grid item xs={12} height='60vh' overflow='auto'>
                            <Accordion square defaultExpanded>
                                <StyledAccordionSummary expandIcon={<ExpandMore color='primary'/>}>
                                    <Typography variant='h2' color={themePalette.palette.primary.main}>
                                        Dodaj Chorobę
                                    </Typography>
                                </StyledAccordionSummary>
                                <AccordionDetails>
                                    <DiseaseForm />
                                </AccordionDetails>
                            </Accordion>
                            <Typography variant='h2' pl={2} color={themePalette.palette.primary.main} my={2}>
                                Wszystkie Choroby
                            </Typography>
                            <Diseases/>
                        </Grid>
                    </TabPanel>
                    <TabPanel value="2">
                        <Grid item xs={12} height='60vh' overflow='auto'>
                            <Accordion square defaultExpanded>
                                <StyledAccordionSummary expandIcon={<ExpandMore color='secondary'/>}>
                                    <Typography variant='h2' color={themePalette.palette.secondary.main}>
                                        Dodaj Symptom
                                    </Typography>
                                </StyledAccordionSummary>
                                <AccordionDetails>
                                    <SymptopmsForm/>
                                </AccordionDetails>
                            </Accordion>
                            <Typography variant='h2' pl={2} color={themePalette.palette.secondary.main} my={2}>
                                Wszystkie Symptomy
                            </Typography>
                            <Symptoms />
                        </Grid>
                    </TabPanel>
                </TabContext>
                </Grid>
            </Paper>
        </Grid>

    )
}