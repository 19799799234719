import { request } from 'clients/client';
import { AxiosResponse } from 'axios';
import {keysToCamel, keysToSnake} from 'shared/helpers/object';
import {
    Attachment,
    AttachmentType,
    CreateAttachment,
    GetAttachmentFilterParams,
    GetAttachmentParams
} from './attachmentClient.types';
import { formatDate } from 'shared/helpers/date';
import {env} from "../../env";

const baseApiUrl = env.REACT_APP_API_URL;

const getAttachmentTypes = () => {
    return request({
        options: {
            url: `${baseApiUrl}file_type/`,
            method: 'GET',
        },
    }).then((data: any): AxiosResponse<AttachmentType[]> => ({
            ...data.data,
            data: data.data.results.map(keysToCamel)
        })
    );
};

const getAttachments = (params: GetAttachmentFilterParams) => {
    return request({
        options: {
            url: `${baseApiUrl}medical_documents/`,
            method: 'GET',
            params: {
                ...keysToSnake(params),
                file_date_after: params?.fileDateAfter ? formatDate(params?.fileDateAfter) : null,
                file_date_before: params?.fileDateBefore ? formatDate(params?.fileDateBefore) : null,
            },
},
    }).then((data: any): AxiosResponse<Attachment[]> => ({
            ...data.data,
            data: data.data.results.map(keysToCamel)
        })
    );
};

const getAttachment = (params: GetAttachmentParams) => {
    return request({
        options: {
            url: `${baseApiUrl}medical_documents/${params.id}`,
            method: 'GET',
        },
    })
};

const deleteAttachment = (id: string) => {
    return request({
        options: {
            url: `${baseApiUrl}medical_documents/${id}`,
            method: 'DELETE',
        },
    })
};

const editAttachment = async (data: CreateAttachment, id: string) => {

    return request({
        options: {
            url: `${baseApiUrl}medical_documents/${id}`,
            method: 'PATCH',
            data: {
                file_name: data.fileName,
                file_type: data.fileType,
                file_date: formatDate(data?.fileDate || new Date())},
        }
    });
}



const createAttachment = async (data: CreateAttachment) => {
    const formData = new FormData()
    formData.append("file", data.file)
    if (data.fileName) formData.append("file_name", data.fileName)
    if (data.fileDate) formData.append("file_date", formatDate(data?.fileDate || new Date()))
    if (data.fileType) formData.append("file_type_id", data.fileType)


    return request({
        options: {
            url: `${baseApiUrl}medical_documents/`,
            method: 'POST',
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }
    });
}

export const attachmentClient = {
    createAttachment,
    deleteAttachment,
    editAttachment,
    getAttachment,
    getAttachments,
    getAttachmentTypes,
};