import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Link, Typography } from '@mui/material';
import { routes } from 'config';
import { themePalette } from 'shared/styles/muiTheme';
import { LayoutContext } from 'contexts/LayoutContext';
import { LoginAuthForm } from './partials/LoginAuthForm';


export const LogIn = () => {

    const { isMobile } = useContext(LayoutContext);

    return (
        <Grid container flexDirection="column" sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <Grid item>
                <Typography component={isMobile ? "h1" : "span"} variant='h1' color={themePalette.palette.primary.main}>
                    Logowanie /
                </Typography>
                <Typography component="span" variant='body2'>
                    Nie masz konta?
                </Typography>
                <Link sx={theme => ({ textDecoration: 'none', marginLeft: 1, color: theme.palette.secondary.main })} component={RouterLink} to={routes.register}>
                    Zarejestruj się
                </Link>
            </Grid>
            <Grid item mt={2}>
                <LoginAuthForm onSubmitButtonText='ZALOGUJ SIĘ'/>
            </Grid>
        </Grid>
    )
}