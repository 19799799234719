import { useQuery } from '@tanstack/react-query';
import { cacheKeys } from 'config';
import { symptomsClient } from 'clients/symptoms/symptomsClient';


export const useSymptoms = () => {
    const { data: { results: symptoms } = {}, status, isLoading } = useQuery(
        [cacheKeys.getAllSymptoms],
        symptomsClient.getAllSymptoms,
    );


    return {
        status,
        isLoading,
        symptoms: symptoms || [],
    };
};
