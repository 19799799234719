import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { cacheKeys } from 'config';
import { medicalHistoryClient } from 'clients/medicalHistory/medicalHistoryClient';


export const useMedicalHistory = () => {
    const { data: { results: medicalHistory} = {}, isLoading, error } = useQuery(
        [cacheKeys.getMedicalHistory],
        medicalHistoryClient.getMedicalHistory,
    );

    return {
        isLoading,
        error: error as AxiosError,
        medicalHistory: medicalHistory || [],
    };
};
