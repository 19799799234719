import React, { useCallback, useContext, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';
import { Alert, Grid, IconButton, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { PlayArrow, Visibility, VisibilityOff } from '@mui/icons-material';
import { LayoutContext } from 'contexts/LayoutContext';
import { UserChangePassword } from 'clients/users/userClient.types';
import { userClient } from 'clients/users/userClient';
import { validPassword } from 'shared/helpers/validation';
import { themePalette } from 'shared/styles/muiTheme';
import { cacheKeys } from 'config';

export const ChangePassword = () => {

    const queryClient = useQueryClient();
    const [error, setError] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword1, setShowNewPassword1] = useState(false);
    const [showNewPassword2, setShowNewPassword2] = useState(false);

    const DEFAULT_VALUES: Partial<UserChangePassword> = {
        oldPassword: '',
        newPassword1: '',
        newPassword2: '',
    };

    const { isMobile }= useContext(LayoutContext);
    const { handleSubmit, control, formState: { isSubmitting, errors, isSubmitted }, watch } = useForm<UserChangePassword>({
        defaultValues: {
            ...DEFAULT_VALUES,
        },
    });

    const onChangePassword = useCallback( async(data: UserChangePassword) => {
        try {
            await userClient.changePassword(data);
            await queryClient.invalidateQueries([cacheKeys.getUser])
            setError(false);
        } catch {
            setError(true);
        }
    }, [queryClient]);

    const newPassword1 = watch("newPassword1")
    const newPassword2 = watch("newPassword2")

    return (
        <Grid container justifyContent="center">
            <Paper sx={{ padding: 4, borderRadius: 8 }}>
                {isSubmitted && !error && <Alert variant="outlined" severity="success">
                    Hasło zostało poprawnie zmienione
                </Alert>}
                {isSubmitted && error && <Alert variant="outlined" severity="error">
                    Nie udało się zmienić hasła
                </Alert>}
                <Typography variant='h2' color={themePalette.palette.primary.main} my={2}>
                    Zmień hasło
                </Typography>
                <form onSubmit={handleSubmit(onChangePassword)}>
                    <Grid container spacing={3} maxWidth={600}>
                        <Grid item xs={12}>
                            <Controller
                                name="oldPassword"
                                control={control}
                                rules={{ required: 'To pole jest wymagane' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        type={showOldPassword ? 'text' : 'password'}
                                        variant="outlined"
                                        label='Stare Hasło'
                                        error={!!errors.oldPassword}
                                        helperText={errors.oldPassword?.message}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton onClick={() => setShowOldPassword(!showOldPassword)}>
                                                    {showOldPassword && <Visibility/>}
                                                    {!showOldPassword && <VisibilityOff/>}
                                                </IconButton>
                                            </InputAdornment>,
                                        }}
                                    />
                                )}
                            />

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="newPassword1"
                                control={control}
                                rules={{
                                    required: 'To pole jest wymagane',
                                    pattern: {
                                        value: validPassword,
                                        message: 'Hasło musi zawierać minimum 8 znaków, małe i duże litery, minimum jedną cyfrę i jeden znak specjalny',
                                    },
                                    validate: newPassword1 => newPassword1 === newPassword2 || 'Hasła nie są jednakowe'

                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        variant="outlined"
                                        type={showNewPassword1 ? 'text' : 'password'}
                                        label='Nowe Hasło'
                                        error={!!errors.newPassword1}
                                        helperText={errors.newPassword1?.message}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton onClick={() => setShowNewPassword1(!showNewPassword1)}>
                                                    {showNewPassword1 && <Visibility/>}
                                                    {!showNewPassword1 && <VisibilityOff/>}
                                                </IconButton>
                                            </InputAdornment>,
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="newPassword2"
                                control={control}
                                rules={{
                                    required: 'To pole jest wymagane',
                                    pattern: {
                                        value: validPassword,
                                        message: 'Hasło musi zawierać minimum 8 znaków, małe i duże litery, minimum jedną cyfrę i jeden znak specjalny',
                                    },
                                    validate: newPassword2 => newPassword2 === newPassword1 || 'Hasła nie są jednakowe'
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        variant="outlined"
                                        label='Potwórz Nowe Hasło'
                                        type={showNewPassword2 ? 'text' : 'password'}
                                        error={!!errors.newPassword2}
                                        helperText={errors.newPassword2?.message}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton onClick={() => setShowNewPassword2(!showNewPassword2)}>
                                                    {showNewPassword2 && <Visibility/>}
                                                    {!showNewPassword2 && <VisibilityOff/>}
                                                </IconButton>
                                            </InputAdornment>,
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent={isMobile ? "center" : "end"}>
                            <LoadingButton
                                size="large"
                                type="submit"
                                variant="contained"
                                sx={theme => ({ backgroundColor: theme.palette.primary.main })}
                                loading={isSubmitting}
                                endIcon={<PlayArrow />}
                            >
                                ZMIEN HASŁO
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Grid>

    )
}