import { FC, ReactNode, useCallback, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import {Grid, Link, Paper, TextField, Typography} from '@mui/material';
import { PlayArrow } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { routes } from 'config';
import { UserLogin } from 'clients/users/userClient.types';
import { validEmail, validPassword } from 'shared/helpers/validation';
import { UserContext } from 'contexts/UserContext/UserContext';
import { LayoutContext } from 'contexts/LayoutContext';
import { ReCaptchaComponent } from 'shared/components/reCaptcha/ReCaptchaComponent';


type FormCrud = UserLogin;

type Field = keyof FormCrud;

interface Props {
    defaultValues?: Partial<FormCrud>;
    onSubmitButtonText: string;
    disabledFields?: Array<Field>;
    hiddenFields?: Array<Field>;
    children?: ReactNode;
    onError?: boolean;

}

const DEFAULT_VALUES: Partial<FormCrud> = {
    username: '',
    password: '',
};

export const LoginAuthForm: FC<Props> = ({
    defaultValues = {},
    onSubmitButtonText,
    hiddenFields,
    disabledFields,
}) => {

    const { login } = useContext(UserContext);
    const { isMobile }= useContext(LayoutContext);

    const { handleSubmit, control, setError, formState: { isSubmitting, errors }} = useForm<FormCrud>({
        defaultValues: {
            ...DEFAULT_VALUES,
            ...defaultValues,
        },
    });

    const isFieldVisible = useCallback((field: Field) => {
        if (!hiddenFields) {
            return true;
        }
        return hiddenFields.indexOf(field) === -1;
    }, [hiddenFields]);

    const isFieldEnabled = useCallback((field: Field) => {
        if (!disabledFields) {
            return true;
        }
        return disabledFields.indexOf(field) === -1;
    }, [disabledFields]);

    const onSubmit = useCallback(async (data: FormCrud) => {
        try {
            const response =  await login(data);
            return response
        } catch (err) {
            setError('username', {message: 'Email lub hasło są nieprawidłowe'})
            setError('password', {message: 'Email lub hasło są nieprawidłowe'})
        }
    }, [setError, login]);



    return (
        <Paper sx={{ padding: isMobile ? 4 : 8, display: 'flex', justifyContent: 'center', borderRadius: 8 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ReCaptchaComponent />
                {isFieldVisible('username') && (
                    <Grid item xs={12}>
                        <Controller
                            name="username"
                            control={control}
                            rules={{
                                required: 'To pole jest wymagane',
                                pattern: {
                                    value: validEmail,
                                    message: 'Nieprawidłowy format email',
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    variant="outlined"
                                    label='Email'
                                    name="email"
                                    error={!!errors.username}
                                    helperText={errors.username?.message}
                                    disabled={!isFieldEnabled('username')}
                                    sx = {{ minWidth: 300}}
                                />
                            )}
                        />
                    </Grid>
                )}
                {isFieldVisible('password') && (
                    <Grid item xs={12} mt={2}>
                        <Controller
                            name="password"
                            control={control}
                            rules={{
                                required: 'To pole jest wymagane',
                                minLength: {
                                    value: 8,
                                    message: 'Hasło musi zawierać minimum 8 znaków',
                                },
                                pattern: {
                                    value: validPassword,
                                    message: 'Hasło musi zawierać minimum 8 znaków, małe i duże litery, minimum jedną cyfrę i jeden znak specjalny',
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    variant="outlined"
                                    autoComplete="off"
                                    label='Hasło'
                                    type="password"
                                    error={!!errors.password}
                                    helperText={errors.password?.message}
                                    disabled={!isFieldEnabled('password')}
                                    sx = {{ minWidth: 300}}
                                />
                            )}
                        />
                    </Grid>
                )}
                <Grid item xs={12} display="flex" justifyContent="end">
                    <Link sx={{ textDecoration: 'none' }} component={RouterLink} to={routes.resetPassword}>
                        <Typography variant="body2">
                            Przypomnij hasło
                        </Typography>
                    </Link>
                </Grid>
                <Grid item xs={12} mt={2} display="flex" justifyContent={isMobile ? "center" : "end"}>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        sx={theme => ({ backgroundColor: theme.palette.primary.main })}
                        endIcon={<PlayArrow />}
                    >
                        {onSubmitButtonText}
                    </LoadingButton>
                </Grid>
            </form>
        </Paper>
    );
};
