import {request} from 'clients/client';
import {PaginatedResults} from "../../shared/helpers/Query";
import {MedicalHistoryTypes} from "./medicalHistoryClient.types";
import {env} from "../../env";

const medicalHistoryApiBaseUrl = env.REACT_APP_API_URL;

const getMedicalHistory = async () => {

    return request({
        options: {
            url: `${medicalHistoryApiBaseUrl}medical_records/`,
            method: 'GET',
        }
    }).then((data): PaginatedResults<MedicalHistoryTypes> => data.data)
}

export const medicalHistoryClient = {
    getMedicalHistory
}