import React, { Fragment, useCallback, useState } from 'react';
import {
    Box,
    Dialog,
    DialogTitle,
    Grid,
    TableBody,
    TableCell,
    TableHead,
    Table,
    TableRow,
    TableContainer,
    DialogContent,
    IconButton,
    Skeleton,
    Typography,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import {useQueryClient} from '@tanstack/react-query';
import {cacheKeys} from 'config';
import { DiseaseType } from 'clients/diseases/diseasesClient.types';
import { SymptopmsForm } from './form/SymptopmsForm';
import { useSymptoms } from 'shared/hooks/symptoms/useSymptoms';
import { SymptomsType } from 'clients/symptoms/symptomsClient.types';
import { symptomsClient } from 'clients/symptoms/symptomsClient';

export const Symptoms = () => {

    const queryClient = useQueryClient();
    const [editedId, setEditedId] = useState('')
    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false);
    };
    const { symptoms, isLoading } = useSymptoms();

    const onSubmit = useCallback(async (data: SymptomsType) => {
        try {
            await symptomsClient.editSymptom(data, editedId)
            handleClose()
        } catch (err) {
            console.log(err);
        }
    }, [ editedId]);
    const editRow = (disease: DiseaseType) => {
        setEditedId(disease.id);
        setOpen(true);
    }

    const onDelete = useCallback(async (id: string) => {
        try {
            await symptomsClient.deleteSymptom(id);
            await queryClient.invalidateQueries([cacheKeys.getAllSymptoms]);
        } catch (err) {
            console.log(err)
        }
    }, [queryClient]);

    if (isLoading) {
        return (
            <Box sx={{ width: '100%' }}>
                {[...Array(5)].map((e, i) => <Skeleton height='50px' animation='pulse' />)}
            </Box>
        )
    }
    if (!isLoading && symptoms && symptoms.length === 0) {
        return (
            <Grid container justifyContent='center'>
                <Typography variant='body2'>
                    Nie masz dodanych symptomów
                </Typography>
            </Grid>
        )
    }

    return (
        <Fragment>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Nazwa</strong></TableCell>
                            <TableCell><strong>Data Symptomu</strong></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {symptoms.map((dis: DiseaseType) => (
                            <TableRow key={dis.id}>
                                <TableCell> {dis.name} </TableCell>
                                <TableCell> {dis.diagnose_date} </TableCell>
                                <TableCell width={90}>
                                    <IconButton sx={{ padding: 1 }} onClick={() => editRow(dis)}>
                                        <Edit cursor='pointer'/>
                                    </IconButton>
                                    <IconButton sx={{ padding: 1 }} onClick={() => onDelete(dis.id)}>
                                        <Delete cursor='pointer'/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle sx={theme => ({ fontSize: 28, color: theme.palette.secondary.main })}>
                    Edytuj symptom
                </DialogTitle>
                <DialogContent>
                    <SymptopmsForm
                        defaultValues={{
                            name: symptoms.find(sym => sym.id === editedId)?.name,
                            diagnoseDate: new Date(symptoms.find(sym => sym.id === editedId)?.diagnose_date || '')
                        }}
                        onSubmitRequest={onSubmit}
                        edit={true}
                    />
                </DialogContent>
            </Dialog>

        </Fragment>

    )
}