import { createTheme, ThemeOptions, responsiveFontSizes } from '@mui/material/styles';

declare module '@mui/material' {
    interface textColor {
        black: string;
        dark: string;
    }
}
export const themePalette = {
    palette: {
        background: {
            paper: '#FFFFFF',
        },
        textColor: {
            black: '#2C2C2C',
            dark: '#071C3B',
        },
        primary: {
            light: '#0e2247',
            main: '#0B8CEE',
        },
        secondary: {
            light: '#2BCE19',
            main: '#2BCE19',
        },
        success: {
            main: '#ADFFB5',
        },
        error: {
            main: '#f51d44',
        },
    },
};

export const defaultTheme = responsiveFontSizes(createTheme({}));


export const baseTheme: ThemeOptions = {
    typography: {
        fontFamily: 'Sagnika Regular, sans-serif',
    },
    palette: {
        ...themePalette.palette,
    },
    shape: {
        borderRadius: 4,
    },

    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundSize: 'auto',
                    backgroundAttachment: 'fixed',
                    backgroundPosition: 'bottom left',
                    backgroundImage: 'url(/assets/mapka-droga.svg)',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                h1: {
                    fontWeight: 700,
                    fontSize: '39px',
                },
                h2: {
                    fontWeight: 700,
                    fontSize: '28px',
                },
                h3: {
                    fontWeight: 700,
                    fontSize: '18px',
                },
                h4: {
                    fontSize: '14px',
                },
                body2: {
                    color: '#959595',
                },
            },


        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiButton: {
            styleOverrides: {
                contained: {
                    backgroundColor: themePalette.palette.secondary.main,
                },
                root: {
                    textTransform: 'none',
                    borderRadius: 20,
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: themePalette.palette.background.paper,
                }
            }
        },
        MuiPaper: {
            defaultProps: {
                elevation: 4,
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: 4,
                },
            },
        },
        MuiInputLabel: {
            defaultProps: {
                shrink: true,
                variant: 'standard',
            },
            styleOverrides: {
                root: {
                    position: 'relative',
                    left: 18,
                    fontSize: defaultTheme.typography.pxToRem(18),
                },
            },
        },
        MuiOutlinedInput: {
            defaultProps: {
                notched: false,

            },
            styleOverrides: {
                root: {
                    borderRadius: '29px',
                       input: {
                            padding: '8px 18px',
                            color: themePalette.palette.textColor.black
                       }
                }
            },
        },

    },
};

export const theme = () => createTheme(baseTheme);


export const muiTheme = theme;