import React, { FC, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import {
    Drawer,
    ListItemIcon,
    ListItemText,
    MenuItem,
    styled,
    Typography,
    IconButton,
    Theme,
    Grid,
    CSSObject,
} from '@mui/material';
import {
    Person,
    History,
    Plagiarism,
    ChevronRight,
    ChevronLeft,
    ExitToApp, Add
} from '@mui/icons-material';
import { common, grey } from '@mui/material/colors';
import { drawerWidth, routes } from 'config';
import { LayoutContext} from 'contexts/LayoutContext';
import { UserContext } from 'contexts/UserContext/UserContext';
import { themePalette } from 'shared/styles/muiTheme';

const ToggleButton = styled(IconButton)<{ open?: boolean }>(({ theme, open }) => ({
    position: 'absolute',
    right: -20,
    top: 40,
    backgroundColor: theme.palette.primary.main,
    color: common.white,
    boxSizing: 'border-box',
    border: '5px solid #FFF'
}));

const StyledListIcon = styled(ListItemIcon)<{ open?: boolean }>(({ open }) => ({
    minWidth: 0,
    paddingRight: 16,
    justifyContent: 'center',
    alignItems: 'center',
}))


const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: 'visible',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: 'visible',
    width: `calc(${theme.spacing(7)} + 2px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 2px)`,
    },
});


// @ts-ignore
const StyledDrawer = styled(Drawer)<{
    open: boolean;
}>(({ open, theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    position: 'relative',
    overflow: 'visible',
    '& .MuiPaper-root': {
        borderTopRightRadius: 30,
        borderBottomRightRadius: 30,
        padding: 4,
        boxShadow: '10px 0px 9px #0000001A',
    },
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));


export const Sidebar: FC = () => {

    const { logout } = useContext(UserContext);
    const { menuOpen, setMenuOpen, isMobile } = useContext(LayoutContext);


    const mainMenuItems = [
        { id: 'account', title: 'ZARZĄDZAJ SWOIMI DANYMI', link: routes.account, icon: <Person/> },
        { id: 'add-data', title: 'DODAJ DANE', link: routes.addData, icon: <Add/> },
        { id: 'history', title: 'HISTORIA CHOROBY', link: routes.history, icon: <History/>},
        { id: 'attachments', title: 'ZARZĄDZANIE PLIKAMI', link: routes.attachments, icon: <Plagiarism/>},
    ]

    if (isMobile) {
        return null;
    }

    return (
        <StyledDrawer variant="permanent" open={menuOpen}>
                <Grid item xs={12} mt={2} sx={{ minHeight: 100, cursor: 'pointer', justifyContent: 'center' }}>
                    <img src='/assets/Logo.svg' height="50px" width="100%" alt='logo' style={{ opacity: menuOpen ? 1 : 0 }}/>
                </Grid>
                <ToggleButton
                    disableRipple
                    open={menuOpen}
                    onClick={() => setMenuOpen(!menuOpen)}
                    children={ menuOpen ? <ChevronLeft/> : <ChevronRight/>}
                />
            <Grid container direction="column" data-onboarding="menu-sidebar">
            {mainMenuItems.map(({ id, title, link, icon }) => (
                    <MenuItem
                        key={id}
                        component={NavLink}
                        to={link}
                        sx={{
                            color: themePalette.palette.primary.light,
                            padding: 2,
                            '& .MuiListItemIcon-root': {
                                color: themePalette.palette.primary.light,
                            },
                            '&.active': {
                                fontSize: 900,
                                borderRadius: 29,
                                background: grey['200'],
                                width: '100%',
                            },
                            '&:hover': {
                                width: 'inherit',
                                borderRadius: 29,
                            }
                        }}
                        data-onboarding={`menu-sidebar-item-${id}`}
                    >
                        <StyledListIcon open={menuOpen}> {icon} </StyledListIcon>
                        <ListItemText sx={{ opacity: menuOpen ? 1 : 0 }}>
                            {title}
                        </ListItemText>

                    </MenuItem>
                    ))}
                <MenuItem
                    onClick={() => logout()}
                    sx={{
                        position: 'absolute',
                        bottom: 20,
                        backgroundColor: grey['100'],
                        maxWidth: '100%',
                        padding: 1.5,
                        borderRadius: 29,
                }}>
                    <StyledListIcon open={menuOpen} sx={{ padding: 0 }}>
                        <ExitToApp/>
                    </StyledListIcon>
                    <ListItemText sx={{ opacity: menuOpen ? 1 : 0 }}>
                        <Typography variant='body2' sx={{ display: menuOpen ? '' : 'none'}}>
                            WYLOGUJ
                        </Typography>
                    </ListItemText>
                </MenuItem>
            </Grid>
        </StyledDrawer>
    );
};
