import React, { useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { Button, Dialog, DialogContent, DialogTitle, Grid, Paper, Typography } from '@mui/material';
import { ArrowBack, RotateLeft, RotateRight, OpenInFull ,ZoomIn, ZoomOut, Download } from '@mui/icons-material';
import { IMAGE_EXTENSIONS, PDF_EXTENSIONS, routes} from 'config';
import { useAttachment } from 'shared/hooks/attachment/useAttachment';
import { themePalette } from 'shared/styles/muiTheme';

export const PDFViewer = () => {

    const { fileId = '' } = useParams();
    const [rotate, setRotate] = useState<number>(0);
    const [scale, setScale] = useState<number>(1);
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const { attachment, status } = useAttachment({
        id: fileId,
    }, {enabled: !!fileId});

    return (
        <Grid container sx={{ overflowX: 'scroll' }}>
            <Button sx={{ justifySelf: 'start' }} onClick={() => navigate(routes.attachments)}>
                <ArrowBack/> POWRÓT
            </Button>
            <Paper sx={{ padding: 4 , borderRadius: 8, width: '100%', position: 'relative' }}>
                <Button
                    onClick={handleClickOpen}
                    sx={{ position: 'absolute' , top: 8, right: 8}}
                >
                    <OpenInFull />
                </Button>
                <Typography component="h1" variant='h1' color={themePalette.palette.primary.main}>
                    Pogląd Pliku
                </Typography>
                {status==='success' && attachment && attachment.file_name && <Typography variant='body2'>
                    {attachment.file_name}
                </Typography>}
                {status==='success' && attachment && IMAGE_EXTENSIONS.includes(attachment.file_extension) &&
                    <Grid container>
                        <Grid item xs={12}
                              sx={{
                                  border: '1px dashed grey',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  overflow: 'auto',
                                  height: '300px',
                              }}>
                            <img src={attachment.file}
                                 style={{
                                    objectFit: 'contain',
                                     maxHeight: '100%',
                                    transform: `rotate(${rotate}deg) scale(${scale})`
                            }}
                                 alt="img"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} mt={2} display='flex' flexDirection='row' justifyContent='space-around'>
                            <Button variant='outlined' onClick={() => setRotate(rotate + 90)}>
                                <RotateLeft /> Obróć w lewo
                            </Button>
                            <Button variant='outlined' onClick={() => setRotate(rotate - 90)}>
                                <RotateRight /> Obróć w prawo
                            </Button>
                        </Grid>
                        <Grid xs={12} sm={6} mt={2} display='flex' flexDirection='row' justifyContent='space-around'>
                            <Button variant='outlined' onClick={() => setScale(scale*1.1)}>
                                <ZoomIn/> Przybliż
                            </Button>
                            <Button variant='outlined' onClick={() => setScale(1)}>
                                1:1
                            </Button>
                            <Button variant='outlined' onClick={() => setScale(scale*0.9)}>
                                <ZoomOut/> Oddal
                            </Button>
                        </Grid>
                        <Grid item xs={12} mt={2} display='flex' justifyContent='center'>
                            <Button variant='outlined' component={"a"} download href={attachment.file} rel="noopener" >
                                <Download/> Pobierz
                            </Button>
                        </Grid>
                    </Grid>
                }
                {status==='success' && attachment && PDF_EXTENSIONS.includes(attachment.file_extension) &&
                    <Grid container flexDirection="column" justifyContent='center' alignItems='center'>
                        <Grid item xs={12} display='flex' justifyContent='center' minWidth='100%'>
                            <object data={attachment.file} height='500'>
                                pdf viewer
                                <a rel="noopener" href={attachment.file} download>
                                    Kliknij tutaj aby pobrać plik.
                                </a>
                            </object>
                        </Grid>
                        <Grid item xs={12} mt={2} display='flex' justifyContent='center'>
                            <Button variant='outlined' component={"a"} download href={attachment.file} rel="noopener" >
                                <Download/> Pobierz
                            </Button>
                        </Grid>
                    </Grid>
                }
                {open && <Dialog open={open} onClose={handleClose} fullScreen>
                    <DialogTitle>
                        <Button onClick={handleClose}>
                            <ArrowBack/> POWRÓT
                        </Button>
                    </DialogTitle>
                    <DialogContent>
                        {status==='success' && attachment && IMAGE_EXTENSIONS.includes(attachment.file_extension) &&
                            <img src={attachment.file} style={{width: '100%', maxHeight: '100%'}} alt='file'/>
                        }
                        {status==='success' && attachment && PDF_EXTENSIONS.includes(attachment.file_extension) &&
                            <Grid container height='100%'>
                                <object data={attachment.file} type="application/pdf" width="100%" height="100%">
                                    pdf viewer
                                    <a rel="noopener" href={attachment.file} download>
                                        Kliknij tutaj aby pobrać plik.
                                    </a>
                                </object>
                            </Grid>
                        }
                    </DialogContent>
                </Dialog>}
            </Paper>
        </Grid>
    )
}