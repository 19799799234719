import React, { Fragment, useContext } from 'react';
import {
    Button,
    Grid,
    Stepper,
    Step,
    StepLabel,
    styled,
    Box,
    StepConnector,
    Typography,
    Paper, CircularProgress
} from '@mui/material';
import {ChildCare, NoteAdd, ModeStandby, PersonAddAlt1, Healing, AddOutlined } from '@mui/icons-material';
import { LayoutContext } from 'contexts/LayoutContext';
import { useMedicalHistory } from 'shared/hooks/medicalHistory/useMedicalHistory';
import { themePalette } from 'shared/styles/muiTheme';
import {useNavigate} from "react-router-dom";
import {routes} from "../../../config";

const ColorlibStepIconRoot = styled('div')<{
    category: string;
}>(({ category, theme }) => ({
    backgroundColor: backgroundIcon[category],
    zIndex: 1,
    color: '#fff',
    width: 35,
    height: 35,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
}));

const StyledConnector = styled(StepConnector)(({ theme }) => ({
    '& .MuiStepConnector-line': {
        borderColor: '#071C3B',
        borderWidth: 2,
        position: 'relative',
        minHeight: 90,
        left: 3,
    }
}));

const StyledYear = styled(Box)<{
    category: string;
}>(({ category, theme }) => ({
    position: 'absolute',
    color: backgroundIcon[category],
    right: 60,
    fontSize: 20,
}))

const StyledLabel = styled(Box)<{
    category: string;
}>(({ theme, category }) => ({
    color: backgroundIcon[category],
    fontSize: 17,
    minWidth: '320px',
    minHeight: '80px',
    padding: 8,
    position: 'absolute',
    backgroundColor: '#F7F7F7',
    top: 0,
    borderRadius: '0px 29px 29px 29px',
}))

const StyledButton = styled(Button)(({ theme }) => ({
    position: 'sticky',
    padding: 30,
    bottom: 10,
    maxWidth: 300,
    marginX: 'auto',
    alignSelf: 'center',
    zIndex: 2,
}));

const backgroundIcon: { [index: string]: string } = {
    'BIRTH': '#071C3B',
    'ATTACHMENT': '#959595',
    'DISEASE': '#0B8CEE',
    'ACCOUNT':'#0e2247',
    'SYMPTOM':'#2BCE19',
    'DOCUMENT':'#959595',
};
export const History = () => {


    const { isMobile }= useContext(LayoutContext);
    const navigate = useNavigate();

    return (
        <Fragment>
            <Grid container display='flex' flexDirection="column" alignItems='stretch' alignSelf='start' width='100%'>
                <Grid item xs={12} mb={2} alignSelf='start'>
                    <Typography variant='h1' color={themePalette.palette.primary.main}>
                        Historia choroby
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {isMobile ?
                        <MedicalSteps />
                        :
                        <Paper sx={{ paddingY: 10, borderRadius: 8, alignSelf: 'unset', minHeight: '80vh' }}>
                            <MedicalSteps />
                        </Paper>
                    }
                </Grid>
                <StyledButton
                    variant='contained'
                    startIcon={<AddOutlined />}
                    onClick={() => navigate(routes.addData)}
                    sx={theme => ({ backgroundColor: theme.palette.primary.main, marginTop: 2 })}
                >
                    <Typography variant='h3'>
                        Uzupełnij więcej informacji
                    </Typography>
                </StyledButton>
            </Grid>
        </Fragment>
    )
}

const MedicalSteps = () => {

    const { medicalHistory, isLoading } = useMedicalHistory();
    const { isMobile }= useContext(LayoutContext)

    return(
        <Grid container sx={{ width: '90%', marginBottom: 10, display: 'flex', justifyContent: 'start', marginLeft: isMobile ? 10 : 20 }}>
            {isLoading && <CircularProgress color='primary' />}
            {medicalHistory.length > 0 &&
                <Stepper orientation="vertical" connector={<StyledConnector />}>
                    {medicalHistory.map((history) => (
                        <Step key={history.title}>
                            <StepLabel
                                sx={{ padding: 0, position: 'relative' }}
                                icon={<CustomIconStep category={history.category} year={history.data}/>}
                            >
                                <StyledLabel category={history.category}>
                                    {history.title_display}
                                    <Typography variant='body2' mt={1}>
                                        {history.time_ago}
                                    </Typography>
                                </StyledLabel>
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            }
        </Grid>
    )
}

type IconType = {
    category: string;
    year: string;
}
const CustomIconStep: React.FC<IconType> = ({category, year}) => {

    const icons: { [index: string]: React.ReactElement } = {
        'BIRTH': <ChildCare />,
        'ATTACHMENT': <NoteAdd />,
        'DOCUMENT': <NoteAdd />,
        'DISEASE': <ModeStandby />,
        'ACCOUNT': <PersonAddAlt1 />,
        'SYMPTOM': <Healing />,
    };

    return (
        <ColorlibStepIconRoot category={category}>
            {icons[String(category)]}
            <StyledYear category={category}>{year.slice(0,4)}</StyledYear>
        </ColorlibStepIconRoot>
    );
}