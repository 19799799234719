import React, {FC, Fragment, ReactNode, useCallback, useContext, useEffect, useState} from 'react';
import { useForm, Controller } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import { PlayArrow } from "@mui/icons-material";
import {
    Checkbox,
    FormLabel,
    FormControlLabel,
    Grid,
    Paper,
    styled,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography, IconButton
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import parse from 'html-react-parser';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { UserRegister } from 'clients/users/userClient.types';
import { phoneRegex, validEmail, validPassword } from 'shared/helpers/validation';
import { LayoutContext } from 'contexts/LayoutContext';
import { useAgreements } from 'shared/hooks/agreement/useAgreements';
import { Agreements } from 'clients/agreements/agreementClient.types';
import { ReCaptchaComponent } from 'shared/components/reCaptcha/ReCaptchaComponent';


type FormCrud = UserRegister;

type Field = keyof FormCrud;

interface Props {
    defaultValues?: Partial<FormCrud>;
    onSubmitRequest: (values: FormCrud ) => void;
    onSubmitButtonText: string;
    disabledFields?: Array<Field>;
    hiddenFields?: Array<Field>;
    children?: ReactNode;
    onError?: boolean;

}


const ContainedToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "auto auto",
    gridGap: "7px",
}));

const StyledToggleButton = styled(ToggleButton)<{
    gender?: string;
}>(({ gender }) => ({
    backgroundImage: `url(/assets/${gender}.svg)`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    minHeight: '80px',
    minWidth: '110px',
    "&.MuiToggleButtonGroup-grouped": {
        borderRadius: "19px !important",
        mx: 1,
        border: "1px solid lightgrey !important"
    }

}));

const DEFAULT_VALUES: Partial<FormCrud> = {
    firstName: '',
    lastName: '',
    email: '',
    birthDate: null,
    agreements: [],
};

export const RegisterUserAuth: FC<Props> = ({
    defaultValues = {},
    onSubmitRequest,
    onSubmitButtonText,
    hiddenFields,
    disabledFields,
}) => {

    const { isMobile }= useContext(LayoutContext);
    const { agreements: agreementsList, status } = useAgreements({
        displayLocation: 'REGISTER',
    });
    const [showMore, setShowMore] = useState<Record<string, boolean>>()

    useEffect(() => {
        const agreementsShowMore: Record<string, boolean> = {}
        agreementsList.map((agreement) => agreementsShowMore[agreement.id] = false)
        setShowMore(agreementsShowMore)
    }, [agreementsList])

    const { handleSubmit, control, watch, register, reset, formState: { isSubmitted, isSubmitting, errors }} = useForm<FormCrud>({
        defaultValues: {
            ...DEFAULT_VALUES,
            ...defaultValues,
            agreements: [...Array(agreementsList.length)]
        },
    });


    const agreementsWatch = watch('agreements') || [];

    useEffect(() => {
        reset()
    }, [agreementsList,reset])



    const isFieldVisible = useCallback((field: Field) => {
        if (!hiddenFields) {
            return true;
        }
        return hiddenFields.indexOf(field) === -1;
    }, [hiddenFields]);

    const isFieldEnabled = useCallback((field: Field) => {
        if (!disabledFields) {
            return true;
        }
        return disabledFields.indexOf(field) === -1;
    }, [disabledFields]);

    const onSubmit = useCallback((data: FormCrud) => {
        return onSubmitRequest(data);
    }, [onSubmitRequest]);

    const password = watch('password');

    return (
        <Paper sx={{ padding: 4, borderRadius: 8 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ReCaptchaComponent />
                <Grid container spacing={3} maxWidth={500}>
                    {isFieldVisible('firstName') && (
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="firstName"
                                control={control}
                                rules={{ required: 'To pole jest wymagane' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        variant="outlined"
                                        label='Imię'
                                        error={!!errors.firstName}
                                        helperText={errors.firstName?.message}
                                        disabled={!isFieldEnabled('firstName')}
                                    />
                                )}
                            />
                        </Grid>
                    )}
                    {isFieldVisible('lastName') && (
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="lastName"
                                control={control}
                                rules={{ required: 'To pole jest wymagane' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        variant="outlined"
                                        label='Nazwisko'
                                        error={!!errors.lastName}
                                        helperText={errors.lastName?.message}
                                        disabled={!isFieldEnabled('lastName')}
                                    />
                                )}
                            />
                        </Grid>
                    )}
                    {isFieldVisible('lastName') && (
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name='birthDate'
                                rules={{ required: 'To Pole jest wymagane'}}
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        {...field}
                                        inputFormat="dd.MM.yyyy"
                                        label="Data Urodzenia"
                                        mask="__.__.____"
                                        showDaysOutsideCurrentMonth
                                        maxDate={new Date()}
                                        openTo="year"
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                error={!!errors?.birthDate}
                                                helperText={errors?.birthDate?.message}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                    )}
                    {isFieldVisible('city') && (
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="city"
                                control={control}
                                rules={{
                                    required: 'To pole jest wymagane',
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        variant="outlined"
                                        label='Miasto'
                                        name="city"
                                        error={!!errors.city}
                                        helperText={errors.city?.message}
                                        disabled={!isFieldEnabled('city')}
                                    />
                                )}
                            />
                        </Grid>
                    )}
                    {isFieldVisible('email') && (
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: 'To pole jest wymagane',
                                    pattern: {
                                        value: validEmail,
                                        message: 'Nieprawidłowy format email',
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        variant="outlined"
                                        label='Email'
                                        name="email"
                                        error={!!errors.email}
                                        helperText={errors.email?.message}
                                        disabled={!isFieldEnabled('email')}
                                    />
                                )}
                            />
                        </Grid>
                    )}
                    {isFieldVisible('phoneNumber') && (
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="phoneNumber"
                                control={control}
                                rules={{
                                    required: 'To pole jest wymagane',
                                    pattern: {
                                        value: phoneRegex,
                                        message: 'Nieprawidłowy format',
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        variant="outlined"
                                        label='Telefon'
                                        name="phone"
                                        type="number"
                                        error={!!errors.phoneNumber}
                                        helperText={errors.phoneNumber?.message}
                                        disabled={!isFieldEnabled('phoneNumber')}
                                    />
                                )}
                            />
                        </Grid>
                    )}
                    {isFieldVisible('sex') && (
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="sex"
                                control={control}
                                rules={{ required: 'To pole jest wymagane' }}
                                render={({ field }) => (
                                    <Fragment>
                                        <FormLabel
                                            sx={{ fontSize: '13.5px', left: '18px', top: '-2px', color: !!errors.sex ? 'red' : '' }}
                                        >
                                            Płeć Biologiczna
                                        </FormLabel>
                                        <ContainedToggleButtonGroup {...field} aria-label="label">
                                            <StyledToggleButton gender="women" value="f" />
                                            <StyledToggleButton gender="men" value="m"/>
                                        </ContainedToggleButtonGroup>
                                    </Fragment>
                                )}
                            />
                            {!!errors.sex &&
                                <Typography color='#f51d44' fontSize={12} ml={2}>
                                    To pole jest wymagane
                                </Typography>
                            }
                        </Grid>
                    )}

                    {isFieldVisible('genderIdentity') && (
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="genderIdentity"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        variant="outlined"
                                        autoComplete="off"
                                        label='Tożsamość Płciowa'
                                        type="genderIdentity"
                                        error={!!errors.genderIdentity}
                                        helperText={errors.genderIdentity?.message}
                                        disabled={!isFieldEnabled('genderIdentity')}
                                    />
                                )}
                            />
                        </Grid>
                    )}
                    {isFieldVisible('password') && (
                        <Grid item xs={12} sm={isFieldVisible('password') ? 6 : 12}>
                            <Controller
                                name="password"
                                control={control}
                                rules={{
                                    required: 'To pole jest wymagane',
                                    minLength: {
                                        value: 8,
                                        message: 'Hasło musi zawierać minimum 8 znaków',
                                    },
                                    pattern: {
                                        value: validPassword,
                                        message: 'Hasło musi zawierać minimum 8 znaków, małe i duże litery, minimum jedną cyfrę i jeden znak specjalny',
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        variant="outlined"
                                        autoComplete="off"
                                        label='Hasło'
                                        type="password"
                                        error={!!errors.password}
                                        helperText={errors.password?.message}
                                        disabled={!isFieldEnabled('password')}
                                    />
                                )}
                            />
                        </Grid>
                    )}
                    {isFieldVisible('repeatedPassword') && (
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="repeatedPassword"
                                control={control}
                                rules={{
                                    required: 'To pole jest wymagane',
                                    validate: repeatPassword => repeatPassword === password || ''
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        variant="outlined"
                                        autoComplete="off"
                                        label='Powtórz Hasło'
                                        type="password"
                                        error={!!errors.repeatedPassword}
                                        helperText={errors.repeatedPassword?.message}
                                        disabled={!isFieldEnabled('repeatedPassword')}
                                    />
                                )}
                            />
                        </Grid>
                    )}
                    {status === 'success' && agreementsList?.length > 0 && agreementsList.map((agreement: Agreements, index: number) => (
                        <Fragment key={index}>
                            <FormControlLabel
                                key={agreement.id}
                                sx={theme => ({
                                    paddingLeft: '24px',
                                    marginTop: 1,
                                    display: 'flex',
                                    alignItems: 'start',
                                    '& .MuiButtonBase-root.Mui-checked': {
                                        color: theme.palette.secondary.main
                                    },
                                    '& .MuiTypography-root': {
                                        color: isSubmitted && agreement.isRequired && !agreementsWatch[index] ? 'red' : 'grey',
                                        fontSize: 12,
                                        margin: 0,
                                        textAlign: 'start',
                                        a: {
                                            color: 'inherit'
                                        },
                                    },

                                })}
                                name={`agreements.${index}`}
                                label={agreement.agreementText &&
                                    <Fragment>

                                            <Grid sx={{ display: 'flex', alignItems: 'start' }}>
                                                <Grid item xs={1}>
                                                    {showMore && agreement.agreementTextContinued && <IconButton
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            const stateAgreement = showMore[agreement.id]
                                                            setShowMore(prevState => ({
                                                                ...prevState,
                                                                [agreement.id]: !stateAgreement,
                                                            }))
                                                        }}
                                                        sx={{ padding: 0.1, marginTop: 1 }}
                                                    >
                                                        {showMore[agreement.id] ? <ExpandLess /> : <ExpandMore />}
                                                    </IconButton>}
                                                </Grid>
                                                <Grid item xs={10}>
                                                    {parse((agreement.agreementText))}
                                                    {showMore && showMore[agreement.id] && parse((agreement.agreementTextContinued))}
                                                </Grid>
                                            </Grid>
                                    </Fragment>
                                }
                                control={
                                <Checkbox
                                    {...register(`agreements.${index}`, {required: agreement.isRequired})}
                                    value={agreement.id}
                                    sx={{ paddingTop: 1 }}
                                />}
                            />
                        </Fragment>
                    ))}


                    <Grid item xs={12} display="flex" justifyContent={isMobile ? "center" : "end"}>
                        <LoadingButton
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                            endIcon={<PlayArrow />}
                        >
                            {onSubmitButtonText}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    );
};
