import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { cacheKeys } from 'config';
import { attachmentClient } from 'clients/attachments/attachmentClient';

type Options = {
    enabled?: boolean
};


export type Params = {
    id: string;
};

export const useAttachment = (params: Params, options: Options) => {
    const { data: { data: attachment } = {}, status, error } = useQuery(
        [cacheKeys.getAttachment, params],
        () => attachmentClient.getAttachment(params),
        { enabled: options.enabled },
    );

    return {
        status,
        error: error as AxiosError,
        attachment,
    };
};
