import { useQuery } from '@tanstack/react-query';
import { cacheKeys } from 'config';
import { diseasesClient } from 'clients/diseases/diseasesClient';


export const useDiseases = () => {
    const { data: { results: diseases} = {}, status, isLoading } = useQuery(
        [cacheKeys.getAllDiseases],
        diseasesClient.getAllDiseases,
    );


    return {
        status,
        isLoading,
        diseases: diseases || [],
    };
};
