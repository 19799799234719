import React, {Fragment, useCallback, useContext, useState} from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { DatePicker } from '@mui/x-date-pickers';
import { PlayArrow } from '@mui/icons-material';
import {
    Alert,
    FormLabel,
    Grid,
    Paper,
    styled,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { UserChangeData } from 'clients/users/userClient.types';
import { userClient } from 'clients/users/userClient';
import { phoneRegex, validEmail } from 'shared/helpers/validation';
import { LayoutContext } from 'contexts/LayoutContext';
import { UserContext} from 'contexts/UserContext/UserContext';
import { themePalette } from 'shared/styles/muiTheme';
import { cacheKeys } from 'config';


type FormCrud = UserChangeData;

const ContainedToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "auto auto",
    gridGap: "7px",
}));

const StyledToggleButton = styled(ToggleButton)<{
    gender?: string;
}>(({ gender }) => ({
    backgroundImage: `url(/assets/${gender}.svg)`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    minHeight: '80px',
    minWidth: '110px',
    "&.MuiToggleButtonGroup-grouped": {
        borderRadius: "19px !important",
        mx: 1,
        border: "1px solid lightgrey !important"
    }

}));


export const ChangePersonalData = () => {

    const [error, setError] = useState(false);
    const { isMobile }= useContext(LayoutContext);
    const { user } = useContext(UserContext);
    const queryClient = useQueryClient();

    const { handleSubmit, control, formState: { isSubmitted, isSubmitting, errors }} = useForm<FormCrud>({
        defaultValues: {
            firstName: user.firstName,
            lastName: user.lastName,
            city: user.city,
            email: user.email,
            birthDate: new Date(user.birthDate),
            phoneNumber: user.phoneNumber,
            sex: user.sex,
            genderIdentity: user.genderIdentity
        },
    });


    const onSubmit = useCallback(async(data: FormCrud) => {
        try {
            if (user.id) {
                await userClient.changePersonalData(data, user.id);
                await queryClient.invalidateQueries([cacheKeys.getUser])
                setError(false);

            }
        } catch {
            setError(true);
        }
    }, [queryClient, user.id]);


    return (
        <Grid container justifyContent="center">
            <Paper sx={{ padding: 4, borderRadius: 8 }}>
                {isSubmitted && !error && <Alert variant="outlined" severity="success">
                   Dane zostały poprawnie zmienione
                </Alert>}
                {isSubmitted && error && <Alert variant="outlined" severity="error">
                    Nie udało się zmienić danych
                </Alert>}
                <Typography variant='h2' color={themePalette.palette.secondary.main} my={2}>
                    Zmień dane
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={3} maxWidth={600}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="firstName"
                                control={control}
                                rules={{ required: 'To pole jest wymagane' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        variant="outlined"
                                        label='Imię'
                                        error={!!errors.firstName}
                                        helperText={errors.firstName?.message}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="lastName"
                                control={control}
                                rules={{ required: 'To pole jest wymagane' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        variant="outlined"
                                        label='Nazwisko'
                                        error={!!errors.lastName}
                                        helperText={errors.lastName?.message}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name='birthDate'
                                rules={{ required: 'To Pole jest wymagane'}}
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        {...field}
                                        inputFormat="dd.MM.yyyy"
                                        label="Data Urodzenia"
                                        mask="__.__.____"
                                        showDaysOutsideCurrentMonth
                                        maxDate={new Date()}
                                        openTo="year"
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                error={!!errors?.birthDate}
                                                helperText={errors?.birthDate?.message}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="city"
                                control={control}
                                rules={{
                                    required: 'To pole jest wymagane',
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        variant="outlined"
                                        label='Miasto'
                                        name="city"
                                        error={!!errors.city}
                                        helperText={errors.city?.message}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: 'To pole jest wymagane',
                                    pattern: {
                                        value: validEmail,
                                        message: 'Nieprawidłowy format email',
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        variant="outlined"
                                        label='Email'
                                        name="email"
                                        error={!!errors.email}
                                        helperText={errors.email?.message}
                                        disabled={true}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="phoneNumber"
                                control={control}
                                rules={{
                                    required: 'To pole jest wymagane',
                                    pattern: {
                                        value: phoneRegex,
                                        message: 'Nieprawidłowy format',
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        variant="outlined"
                                        label='Telefon'
                                        name="phone"
                                        error={!!errors.phoneNumber}
                                        helperText={errors.phoneNumber?.message}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="sex"
                                control={control}
                                rules={{ required: 'To pole jest wymagane' }}
                                render={({ field }) => (
                                    <Fragment>
                                        <FormLabel
                                            sx={{ fontSize: '13.5px', left: '18px', top: '-2px', color: !!errors.sex ? 'red' : '' }}
                                        >
                                            Płeć Biologiczna
                                        </FormLabel>
                                        <ContainedToggleButtonGroup {...field} aria-label="label">
                                            <StyledToggleButton gender="women" value="f" />
                                            <StyledToggleButton gender="men" value="m"/>
                                        </ContainedToggleButtonGroup>
                                    </Fragment>
                                )}
                            />
                            {!!errors.sex &&
                                <Typography color='#f51d44' fontSize={12} ml={2}>
                                    To pole jest wymagane
                                </Typography>
                            }
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="genderIdentity"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        variant="outlined"
                                        autoComplete="off"
                                        label='Tożsamość Płciowa'
                                        type="genderIdentity"
                                        error={!!errors.genderIdentity}
                                        helperText={errors.genderIdentity?.message}
                                    />
                                )}
                            />
                        </Grid>



                        <Grid item xs={12} display="flex" justifyContent={isMobile ? "center" : "end"}>
                            <LoadingButton
                                size="large"
                                type="submit"
                                variant="contained"
                                loading={isSubmitting}
                                endIcon={<PlayArrow />}
                            >
                                ZMIEN DANE
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Grid>
    );
};
