import { Button, Grid, Typography } from '@mui/material';
import { themePalette } from 'shared/styles/muiTheme';
import { useNavigate } from 'react-router-dom';
import { routes } from 'config';
import {useContext} from "react";
import {LayoutContext} from "../../../../contexts/LayoutContext";

export const MainPage = () => {

    const navigate = useNavigate();
    const { isMobile } = useContext(LayoutContext);


    return (
        <Grid container maxWidth="xl">
            <Grid item xs={12} sm={6}>
                <Typography variant="h1" fontFamily='Sagnika Bold' color={ themePalette.palette.primary.main }>
                    Twoja historia znaczy dla świata więcej niż myślisz.
                </Typography>
                <Typography variant="h1" fontFamily='Sagnika Bold' color={ themePalette.palette.secondary.main }>
                    Podziel się nią i pomóż tym, którzy wciąż poszukują diagnozy.
                </Typography>
                <Grid container spacing={2} my={2}>
                    <Grid item xs={6}>
                        <Button variant="contained" fullWidth onClick={() => navigate(routes.register)}>
                            Weź udział w projekcie!
                        </Button>
                    </Grid>
                </Grid>
                <Typography variant='body2'>
                    Już dziś, bez wychodzenia z domu, możesz mieć wpływ na szybszą diagnostykę i nowe formy terapii w
                    kierunku rzadkiej choroby na którą cierpisz Ty lub Twoi bliscy. Przekaż nam swoje dane medyczne,
                    aby wesprzeć świat nauki i dać szasnę tym, którzy nadal nie wiedzą, co im dolega.
                </Typography>

            </Grid>
            <Grid item xs={12} sm={6} textAlign={isMobile ? "center" : 'right'}>
                <img src='/assets/dashboard_main_image.svg' alt='main' width='320px' height='100%'/>
            </Grid>
        </Grid>
    )
}