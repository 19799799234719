import { Box, Grid, styled, Typography } from '@mui/material';
import { common } from '@mui/material/colors';
import { themePalette } from 'shared/styles/muiTheme';

const Main = styled(Grid)<{
    bgcolor?: string;
    imagetype?: string;
}>(({ bgcolor, imagetype, theme }) => ({
    backgroundColor: bgcolor,
    backgroundImage: `url(/assets/mapka-droga${imagetype}.svg)`,
    backgroundSize: 'contain',
    backgroundRepeat: 'repeat-x',
    width: '100%',
    marginTop: 8,
    justifyContent: 'center',
    textAlign: 'center',
    minHeight: '300px',
}));

export const PlatformDescription = () => {

    return (
        <Main container imagetype=''>
            <Grid item xs={12} display="flex" flexDirection="column" alignItems="center" >
                <Typography variant="h1" fontFamily='Sagnika Bold' color={ themePalette.palette.primary.main }>
                    Nasza platforma to
                </Typography>
                <Typography variant="h1" fontFamily='Sagnika Bold' color={ themePalette.palette.secondary.main }>
                    przestrzeń
                </Typography>
            </Grid>
            <Grid item xs={8} justifyContent="center">
                <Typography my={3} variant='body2'>
                    W której społeczność pacjentów cierpiących na choroby rzadkie ma okazję pomóc osobom wciąż
                    niezdiagnozowanym. Każda historia pacjenta i jego ścieżka do rozpoznania to cenna lekcja dla lekarzy
                    i naukowców specjalizujących się w chorobach rzadkich oraz ultrarzadkich.
                </Typography>
                <Typography variant='body2'>
                    Konto pacjenta Fundacji
                    Saventic to także miejsce, w którym zdigiatlizujesz i zabezpieczysz swoje dane medyczne.
                    Będziesz mieć do nich dostęp oraz możliwość aktualizacji.
                </Typography>
            </Grid>
            <Grid container mt={4} spacing={2}>
                <Grid item xs={12} sm={3}>
                    <img src='/assets/dashboard1.svg' alt='1' height='180px' width="100%"/>
                    <Typography variant="h3">Przypomnij sobie swoją drogę do diagnozy.</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <img src='/assets/dashboard2.svg' alt='2' height='180px' width="100%"/>
                    <Typography variant="h3">Zabezpiecz wszystkie dokumenty w wersji elektronicznej.</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <img src='/assets/dashboard3.svg' alt='3' height='180px' width="100%"/>
                    <Typography variant="h3">Przeglądaj je w koncie pacjenta.</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <img src='/assets/dashboard4.svg' alt='4' height='180px' width="100%"/>
                    <Typography variant="h3">Wesprzyj badania prowadzone przez naukowców na całym świecie.</Typography>
                </Grid>
            </Grid>
            <Grid container sx={{ backgroundColor: '#F7F7F7', justifyContent: 'center', padding: 4, marginY: 10, position: 'relative' }}>
                <Grid item xs={12} sm={8}>
                    <Typography variant='body2'>
                        Jedne z głównych zadań naszego Stowarzyszenia to wspieranie wszelkich inicjatyw, które mają na celu poprawę
                        losu chorych. Z całego serca pragniemy dotrzeć do kilkuset wciąż niezdiagnozowanych polskich pacjentów,
                        aby jak najszybciej mogli rozpocząć życiodajną terapię. Wspieramy Fundację Saventic ponieważ wierzymy,
                        że nasze indywidualne historie oraz doświadczenia mogą mieć wpływ na przyśpieszenie diagnostyki tej rzadkiej
                        przypadłości.
                    </Typography>
                    <Typography fontWeight={800} mt={2}>Błażej Jelonek</Typography>
                    <Typography color={themePalette.palette.primary.main} mb={8}> Stowarzyszenie Rodzin z Chorobą Gauchera </Typography>
                </Grid>
                <Box sx={{
                    backgroundImage: 'url(/assets/photoBlazej.svg)',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    justifyContent: 'center',
                    backgroundSize: 'cover',
                    borderRadius: 50,
                    width: 150,
                    height: 150,
                    position: 'absolute',
                    bottom: -75,
                }} />
            </Grid>
            <Main container
                  imagetype='1'
                  bgcolor={themePalette.palette.textColor.dark}
                  color={common.white}
                  padding={8}
                  mt={8}
                  display='flex'
                  justifyContent='space-between'
            >
                <Grid item xs={12} sm={4} textAlign='start'>
                    <Box>
                        <Typography variant='h1' color={themePalette.palette.primary.main}>Korzyści</Typography>
                        <Typography variant='h1'>dla pacjenta i jego rodziny</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} mt={2} textAlign='start' display="flex" flexDirection='column' justifyContent='space-between'>
                    <Box>
                        <Typography variant='h3'>Twoja historia może mieć wpływ</Typography>
                        <Typography variant='h4'>na postęp w dziedzinie diagnostyki i terapii chorób rzadkich.</Typography>
                    </Box>
                    <Box my={2}>
                        <Typography variant='h3'>Otrzymasz bezpieczne miejsce</Typography>
                        <Typography variant='h4'>do przechowywania swojej dokumentacji.</Typography>
                    </Box>
                    <Box>
                        <Typography variant='h3'>Dołączysz do społeczności pacjentów,</Typography>
                        <Typography variant='h4'>którym nie jest obojętny los tych, którzy wciąż poszukują diagnozy.</Typography>
                    </Box>
                    <Box my={2}>
                        <Typography variant='h3'>Będziesz na bieżąco</Typography>
                        <Typography variant='h4'>z informacjami o nowych badaniach i formach terapii w kierunku chorób rzadkich.</Typography>
                    </Box>

                </Grid>

            </Main>
        </Main>
    )
}