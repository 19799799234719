import { Grid } from '@mui/material';
import { MainPage } from './partials/MainPage';
import { Steps } from './partials/Steps';
import { PlatformDescription } from './partials/PlatformDescription';
import { FAQ } from 'views/Public/FAQ/FAQ';
import {RefObject} from "react";

type Props = {
    aboutRef: RefObject<HTMLDivElement>;
    faqRef: RefObject<HTMLDivElement>;
}

export const Dashboard: React.FC<Props> = ({ aboutRef: about, faqRef: faq}) => {

    return (
        <Grid container maxWidth="xl">
           <MainPage />
            <Steps />
            <Grid item ref={about}>
                <PlatformDescription />
            </Grid>
            <Grid item ref={faq}>
                <FAQ/>
            </Grid>

        </Grid>
    )
}