import React from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Root } from 'views/Root';
import { muiTheme } from 'shared/styles/muiTheme';
import { LayoutContextProvider } from './contexts/LayoutContext';
import { UserContextProvider } from 'contexts/UserContext/UserContext';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: 360000, // 6 minutes
        },
    },
});
const App: React.FC = () => {

  const theme = muiTheme();

  return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
          <QueryClientProvider client={queryClient}>
              <UserContextProvider>
                  <LayoutContextProvider>
                      <ThemeProvider theme={theme}>
                          <CssBaseline/>
                          <Root/>
                      </ThemeProvider>
                  </LayoutContextProvider>
              </UserContextProvider>
          </QueryClientProvider>
      </LocalizationProvider>

  );
}

export default App;

